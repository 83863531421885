import React from 'react'
import authApi from '../../apis/auth'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import qs from 'qs'
import * as userActions from '../../store/actions/user'
import { withRouter } from "react-router-dom"

class Reset extends React.Component {
    state = {
        password: '',
        passwordConfirm: '',
        token: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).token,
        errors: [],
        isFetching: false
    }

    submit = (e) => {
        e.preventDefault();

        if(this.state.isFetching)
            return

        this.setState({isFetching: true})
        
        authApi.reset({
            token: this.state.token,
            password: this.state.password,
            passwordConfirm: this.state.passwordConfirm,
        }).then(response => {
            if(response.success) {
                this.props.history.push('/login')
            } else {
                this.setState({errors: response.errors})
            }
            this.setState({isFetching: false})
        })
    }

    render() {
        return <div className="auth-block">
            <div className="auth-card">
                {this.state.isFetching && <div className="d-flex justify-content-center loader">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>}

                <h2>Reset password</h2>

                <form onSubmit={this.submit}>
                    <div className="mb-3">
                        <label className="form-label">Password</label>
                        <input className="form-control" type="password" placeholder="Password" onChange={(e) => {
                            this.setState({password: e.target.value})
                        }} />
                        {this.state.errors.filter((error) => error.param === 'password').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Confirm password</label>
                        <input className="form-control" type="password" placeholder="Confirm password" onChange={(e) => {
                            this.setState({passwordConfirm: e.target.value})
                        }} />
                        {this.state.errors.filter((error) => error.param === 'passwordConfirm').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}
                    </div>

                    {this.state.errors.filter((error) => error.param === 'all').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}

                    <button className="button btn-full" type="submit">Reset</button>
                </form>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Reset))