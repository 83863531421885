import React from 'react'
import logo from '../assets/images/Logo.svg'

class Ban extends React.Component {
    render() {
        return <>
            <div style={{display: 'flex', height: '100vh', width: '100%', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                <img style={{width: 300, marginTop: 30, justifyContent: 'flex-start'}} src={logo} alt="TheTutor - learn all what you want." />
                <div className="col"></div>
                <p style={{fontSize: 24}}>Your account was banned.</p>
                <div className="col"></div>
            </div>
        </>
    }
}

export default Ban