import React from 'react'
import countries from "countries-list";
import Rating from './Rating';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Avatar from '../profile/Avatar';
import CountryFlag from '../ContryFalg';

class TutorCard extends React.Component {
    render() {
        return <div className="tutor-card col-12 col-md-12 col-lg-12">
            <div className="avatar">
                <Avatar user={this.props.tutor} size={160} />
                {/* <div className="country">{Object.values(countries.countries).find(country => country.name === this.props.tutor.country).emoji}<br/>{this.props.tutor.country}</div> */}
            </div>
            <div className="info col">
                <div className="name" onClick={() => {
                    this.props.history.push(`/profiles/${this.props.tutor._id}`)
                }}>
                    {/* <CountryFlag country={this.props.tutor.country} /> */}
                    {this.props.tutor.fullName}
                </div>
                <div className="languages">
                    <div>Native Language: <span>{this.props.tutor.native}</span></div>
                    <div className="col" />
                    {!!this.props.tutor.fluent && <><div>Fluent Language: <span>{this.props.tutor.fluent}</span></div></>}
                </div>
                {/* {this.props.tutor.categories && <div className="categories">
                    {this.props.tutor.categories.map(category => <span className="badge bg-primary" key={category._id}>{category.name}</span>)}
                </div>} */}
                <div className="description" style={{opacity: !this.props.tutor.description.length ? 0.5 : 1}}>
                    {!!this.props.tutor.description.length && <>
                        {this.props.tutor.description}
                    </>}
                    {!this.props.tutor.description.length && <>
                        No info.
                    </>}
                </div>
            </div>
            {/* <div className="col"></div> */}
            <div className="actions">
                <Rating rating={this.props.tutor.rating} />
                <div className="price">
                    from <span>£{this.props.tutor.price}</span> per lesson
                </div>
                {this.props.user.isAuth && <>
                    <Link to={`/profiles/${this.props.tutor._id}?part=booking`} className="button btn-filled">Book a lesson</Link>
                    <Link to={`/chats/${this.props.tutor._id}`} className="button btn-empty">Message</Link>
                </>}
                {!this.props.user.isAuth && <>
                    <a className="button btn-empty" onClick={() => {
                        this.props.history.push('/login')
                    }}>Login for contact with tutor</a>
                </>}
                
                {this.props.tutor.availableTrial && <div className="trial-active">Trial lesson available</div>}
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
  }
  
export default connect(
    mapStateToProps
)(withRouter(TutorCard))