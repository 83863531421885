import React from 'react'
// import Rating from './Rating';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Avatar from '../profile/Avatar';
import LessonAvatar from '../subject/Avatar'
import { getDateWithMonth, getHM } from '../../controllers/DateController';
import lessonApi from '../../apis/lesson'
import ChangeTimeModal from './ChangeTimeModal';

class LessonCard extends React.Component {
    state = {
        show: true,
        subjectModal: false,
    }

    cancel() {
        lessonApi.cancel({_id: this.props.lesson._id}).then(response => {
            if(response.success) {
                this.setState({show: false})
            }
        })
    }

    render() {
        if(!this.state.show)
            return null

        return <div className="tutor-card col-12 col-md-12 col-lg-12">
            {!!this.state.subjectModal && <ChangeTimeModal data={this.state.subjectModal} bookModal={(time, subject) => {}} user={this.props.user} onClose={() => {this.setState({subjectModal: false})}} />}
            <div className="avatar" style={{alignSelf: 'flex-start'}}>
                <LessonAvatar avatar={this.props.lesson.subject.thumbnail} size={160} />
            </div>
            {/* <div className="main-info col">
                Status: 
                {this.props.lesson.status === 'wait_pay' && <span class="badge bg-danger">Wait confirm</span>} 
                {this.props.lesson.status === 'wait' && <span class="badge bg-primary">Wait start</span>} 
                {this.props.lesson.status === 'active' && <span class="badge bg-success">Active</span>} 
                {this.props.lesson.status === 'ended' && <span class="badge bg-secondary">Ended</span>}  */}
                {/* Study area: <b>{this.props.lesson.category.name}</b>&nbsp; */}
                {/* Booked at: {
                        getHM(this.props.lesson.createdAt) + 
                        ' ' + 
                        getDateWithMonth(this.props.lesson.createdAt)
                    }
            </div> */}
            {/* <div className="members">
                <div className="member" onClick={() => {
                    this.props.history.push(`/profiles/${this.props.lesson.tutor._id}`)
                }}>
                    <div className="title">Tutor</div>
                    <Avatar user={this.props.lesson.tutor} size={40} />
                    <div className="name">{this.props.lesson.tutor.fullName}</div>
                </div>
                <div className="member" onClick={() => {
                    this.props.history.push(`/profiles/${this.props.lesson.student._id}`)
                }}>
                    <div className="title">Student</div>
                    <Avatar user={this.props.lesson.student} size={40} />
                    <div className="name">{this.props.lesson.student.fullName}</div>
                </div>
            </div> */}

            <div className="info col">
                <div className="tutor" style={{flexDirection: 'row', display: 'flex'}} onClick={() => {
                    this.props.history.push(`/profiles/${this.props.lesson.tutor._id}`)
                }}>
                    <Avatar user={this.props.lesson.tutor} size={58} />
                    <div className="name">{this.props.lesson.tutor.fullName}</div>
                </div>
                <div className="name" style={{color: '#000000'}}>
                    {/* <CountryFlag country={this.props.tutor.country} /> */}
                    {this.props.lesson.subject.title}
                </div>
                {(this.props.lesson.status === 'wait_pay' || this.props.lesson.status === 'wait') && <div className="mb-3 mt-3 times">
                    <p>Lesson starts at: <br/><span>{
                        getHM(this.props.lesson.startTime) + 
                        ' ' + 
                        getDateWithMonth(this.props.lesson.startTime)
                    }</span></p>
                    <p>End of lesson at: <br/><span>{
                        getHM(this.props.lesson.endTime) + 
                        ' ' + 
                        getDateWithMonth(this.props.lesson.endTime)
                    }</span></p>
                </div>}

                {(this.props.lesson.status === 'active') && <div className="mb-3 mt-3">
                    <p>Password in zoom: <br/><b style={{color: '#000'}}>{this.props.lesson.meetPassword}</b></p>
                </div>}

                {(this.props.lesson.status === 'ended') && <div className="mb-3 mt-3">
                    {
                        (
                            (this.props.lesson.student._id === this.props.user._id && !this.props.lesson.reviewFromTutor) || 
                            (this.props.lesson.tutor._id === this.props.user._id && !this.props.lesson.reviewFromStudent)
                        ) && 
                        <p>Waiting review from {this.props.lesson.student._id === this.props.user._id ? 'tutor' : 'student'}.</p>
                    }
                </div>}
            </div>
            {/* <div className="col"></div> */}
            <div className="actions" style={{flexDirection: 'column', minWidth: '150px'}}>
                {this.props.lesson.status === 'wait' && <button className="button btn-empty" onClick={() => {
                    this.setState({
                        subjectModal: {
                            userId: this.props.lesson.tutor._id,
                            subject: this.props.lesson.subject,
                            lesson: this.props.lesson
                        }
                    })
                }}>Change time</button>}
                {this.props.lesson.status === 'wait_pay' && this.props.lesson.student._id === this.props.user._id && <a href={`${this.props.lesson.payLink}`} className="button btn-filled">Confirm for {this.props.lesson.price} £</a>}
                {this.props.lesson.status === 'wait_pay' && this.props.lesson.student._id === this.props.user._id && <button onClick={() => {this.cancel()}} className="button btn-cancel">Cancel</button>}
                {(this.props.lesson.status === 'wait' || this.props.lesson.status === 'active') && <Link to={`/chats/${this.props.lesson.student._id === this.props.user._id ? this.props.lesson.tutor._id : this.props.lesson.student._id}`} className="button btn-empty">Open chat</Link>}
                {this.props.lesson.status === 'active' && <a target="_blank" href={`${this.props.lesson.tutor._id === this.props.user._id ? this.props.lesson.startUrl : this.props.lesson.joinUrl}`} className="button btn-filled">Open zoom</a>}
                {
                    this.props.lesson.status === 'ended' && (
                        (this.props.lesson.student._id === this.props.user._id && !this.props.lesson.reviewFromStudent) || 
                        (this.props.lesson.tutor._id === this.props.user._id && !this.props.lesson.reviewFromTutor)
                    ) && 
                    <Link className="button btn-empty" to={`/leave-review?lessonId=${this.props.lesson._id}`}>Leave a review</Link>
                }
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
  }
  
export default connect(
    mapStateToProps
)(withRouter(LessonCard))