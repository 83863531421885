import React from 'react'
import lessonApi from '../../apis/lesson'
import LessonCard from '../../components/lesson/LessonCard'
import Pagination from '../../components/Pagination'
import SearchIcon from '@material-ui/icons/Search';

class Lessons extends React.Component {
    state = {
        lessons: [],
        pagination: {},
        isFetching: true,
    }

    componentDidMount() {
        this.getLessons({page: 1})
    }

    getLessons = (params) => {
        this.setState({isFetching: true})
        
        lessonApi.getList({...params, perPage: 10}).then(response => {
            if(response.success) {
                this.setState({lessons: response.lessons, pagination: response.pagination})
            }

            this.setState({isFetching: false})
        })
    }

    onFilter = (filters) => {
        this.setState({filters})
        this.getLessons({
            page: 1,
            ...filters
        })
    }

    render() {
        return <div className="find-tutor container">
            <h1>MY LESSONS</h1>
            {/* <TutorFilters onFilter={this.onFilter} /> */}
            {this.state.isFetching && <div className="d-flex justify-content-center loader">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>}

            {!this.state.isFetching && !!this.state.lessons.length && this.state.lessons.map(lesson => {
                return <LessonCard lesson={lesson} key={lesson._id} />
            })}

           <Pagination paginate={(page) => {
                this.getLessons({
                    page, 
                    // filters: this.state.filters
                })
            }} pagination={this.state.pagination} currentPage={this.state.pagination.page} />

            {!this.state.isFetching && !this.state.lessons.length && <div className="empty-data">
                <SearchIcon /><br/>
                You don't have a single lesson yet.
            </div>}
        </div>
    }
}

export default Lessons