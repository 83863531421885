import React from 'react'
import StarIcon from '@material-ui/icons/Star';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import StarBorderIcon from '@material-ui/icons/StarBorder';

class Rating extends React.Component {
    render() {
        return <div className="rating">
            <span>({this.props.rating.total} reviews)</span>

            {this.props.rating.average <= 0.75 && this.props.rating.average >= 0.25 && <StarHalfIcon />}
            {this.props.rating.average > 0.75 && <StarIcon />}
            {this.props.rating.average < 0.25 && <StarBorderIcon />}

            {this.props.rating.average <= 1.75 && this.props.rating.average >= 1.25 && <StarHalfIcon />}
            {this.props.rating.average > 1.75 && <StarIcon />}
            {this.props.rating.average < 1.25 && <StarBorderIcon />}

            {this.props.rating.average <= 2.75 && this.props.rating.average >= 2.25 && <StarHalfIcon />}
            {this.props.rating.average > 2.75 && <StarIcon />}
            {this.props.rating.average < 2.25 && <StarBorderIcon />}

            {this.props.rating.average <= 3.75 && this.props.rating.average >= 3.25 && <StarHalfIcon />}
            {this.props.rating.average > 3.75 && <StarIcon />}
            {this.props.rating.average < 3.25 && <StarBorderIcon />}

            {this.props.rating.average <= 4.75 && this.props.rating.average >= 4.25 && <StarHalfIcon />}
            {this.props.rating.average > 4.75 && <StarIcon />}
            {this.props.rating.average < 4.25 && <StarBorderIcon />}
        </div>
    }
}


export default Rating