import React from 'react'
import SimpleBar from 'simplebar-react';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

export default class Select extends React.Component {
    constructor(props) {
        super(props);

        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    state = {
        show: false
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({show: false})
        }
    }

    render() {
        return <div ref={this.wrapperRef} tabIndex="0" className={`input-auth select ${this.state.show ? 'active' : ''}`} onClick={() => {
            this.setState({show: true})
        }}>
            {this.props.value}

            {!this.state.show && <ArrowRightIcon />}
            {this.state.show && <ArrowDropDownIcon />}

            <SimpleBar className="values">
                {this.props.values.map(value => {
                    return <div key={value.value} className={`value ${value.value === this.props.value ? 'active' : ''}`} onClick={(e) => {
                        e.stopPropagation()
                        this.setState({show: false})
                        this.props.onClick(value.value)
                    }}>
                        {value.label}
                    </div>
                })}
            </SimpleBar>
        </div>
    }
}