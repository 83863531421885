import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import userApi from '../../apis/user'
import MyProfile from '../../components/profile/MyProfile'
import OtherProfile from '../../components/profile/OtherProfile'
import { setCustomTitle } from '../../controllers/FunctionsController'
import ErrorIcon from '@material-ui/icons/Error';

class Profile extends React.Component {
    state = {
        userId: this.props.match.params._id,
        user: false,
        me: false,
        errors: [],
        isFetching: true,
        error: false
    }

    componentDidMount() {
        if(this.props.user.isAuth && this.state.userId === this.props.user._id) {
            this.setState({me: true, isFetching: false, user: this.props.user})
            
            setTimeout(() => {
                setCustomTitle(`${this.props.user.fullName} - ${process.env.REACT_APP_NAME}`)
            }, 100);
        } else {
            this.getUser()
        }
    }

    getUser = () => {        
        userApi.getUser({_id: this.state.userId}).then(response => {
            if(response.success) {
                this.setState({user: response.user})

                setTimeout(() => {
                    setCustomTitle(`${response.user.fullName} - ${process.env.REACT_APP_NAME}`)
                }, 100);
            } else {
                this.setState({errors: response.errors, error: true})
            }

            this.setState({isFetching: false})
        })
    }

    render() {
        if(this.state.error)
            return <div className="profile-page">
                <div className="empty-data">
                    <ErrorIcon /><br/>
                    {this.state.errors[0].msg}
                </div>
            </div>

        return <div className="profile-page">
            {this.state.isFetching && <div className="d-flex justify-content-center loader">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>}

            {this.state.me && this.props.user.isAuth && <MyProfile />}

            {((!this.state.isFetching && !this.state.me) || (this.state.me && !this.props.user.isAuth)) && !!this.state.user && <OtherProfile user={this.state.user} />}
        </div>
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
    }
}

export default connect(mapStateToProps)(withRouter(Profile))