import React from 'react'
import { withRouter } from "react-router-dom"
import { getHM, timeAt } from '../../controllers/DateController'
import Avatar from '../profile/Avatar'
import DoneAllIcon from '@material-ui/icons/DoneAll';
import DoneIcon from '@material-ui/icons/Done';
import { connect } from 'react-redux';

class Message extends React.Component {
    render() {
        let isHistoryDate = true
        let moreMinute = false

        if(this.props.prevMessage) {
            let date1 = new Date(this.props.message.createdAt);
            let date2 = new Date(this.props.prevMessage.createdAt);
            
            if(
                date1.getFullYear() === date2.getFullYear() && 
                date1.getMonth() === date2.getMonth() && 
                date1.getDate() === date2.getDate()
            ) {
                isHistoryDate = false
            }

            moreMinute = Math.abs(date1 - date2) / (36e5 / 60) > 1 ? true : false
        }

        let isFirst = (
            ((this.props.prevMessage && 
            this.props.prevMessage.user._id !== this.props.message.user._id) || 
            !this.props.prevMessage) || moreMinute
        )

        let isNew = (
            !this.props.message.isRead &&
            this.props.message.user._id !== this.props.user._id && 
            this.props.prevMessage.isRead
        )

        return <>
            {isNew && <div className="date-at">New messages</div>}
            {isHistoryDate && <div className="date-at">{timeAt(this.props.message.createdAt)}</div>}

            <div className={`chat-message ${this.props.message.user._id === this.props.user._id ? 'my' : 'other'}`}>
                {isFirst && <span onClick={() => {
                    this.props.history.push(`/profiles/${this.props.message.user._id}`)
                }}>
                    <Avatar user={this.props.message.user} size={40} />
                </span>}

                {!isFirst && <div className="avatar" style={{width: 40, cursor: 'default'}} />}

                <div className="user-info">
                    <div className="message-text">
                        {this.props.message.text.replace(/&nbsp;/g, '')
                            .replace(/&amp;/g, '&')
                            .replace(/&gt;/g, '>')
                            .replace(/&lt;/g, '<')
                            .replace(/(^\s*(?!.+)\n+)|(\n+\s+(?!.+)$)/g, "")
                            .replace(/(\r\n|\r|\n){2,}/g, '$1\n')}
                    </div>
                </div>
                
                <div className="col"></div>
                
                {/* <div className="status">
                    {this.props.message.user._id === this.props.user._id && this.props.message.isRead && <DoneAllIcon style={{color: 'var(--main-color)'}} />}
                    {this.props.message.user._id === this.props.user._id && !this.props.message.isRead && <DoneIcon style={{color: 'var(--main-color)'}} />}
                </div> */}
            </div>
        </>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

export default connect(mapStateToProps)(withRouter(Message))