import React from 'react'
import reviewApi from '../../apis/review'
import lessonApi from '../../apis/lesson'
import { connect } from 'react-redux'
import { Link, withRouter } from "react-router-dom"
import qs from 'qs'
import SetRating from '../../components/review/SetRating'

class LeaveReview extends React.Component {
    state = {
        text: '',
        errors: [],
        lesson: false,
        isFetching: true,
        lessonId: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).lessonId,
        communication: 5,
        understanding: 5,
        interactivity: 5,
        attentiveness: 5
    }

    componentDidMount() {
        lessonApi.get({_id: this.state.lessonId}).then(response => {
            if(response.success) {
                this.setState({
                    lesson: response.lesson,
                    isFetching: false
                })
            } else {
                this.props.history.push('/my-lessons')
            }
        })
    }

    submit = (e) => {
        e.preventDefault();

        if(this.state.isFetching)
            return

        this.setState({isFetching: true})
        
        reviewApi.sendReview({
            lessonId: this.state.lesson._id,
            text: this.state.text,
            communication: this.state.communication,
            understanding: this.state.understanding,
            interactivity: this.state.interactivity,
            attentiveness: this.state.attentiveness
        }).then(response => {
            if(response.success) {
                this.props.history.push('/my-lessons')
            } else {
                this.setState({errors: response.errors})
            }

            this.setState({isFetching: false})
        })
    }

    render() {
        return <div className="auth-block">
            <div className="auth-card" style={{width: 400}}>
                {this.state.isFetching && <div className="d-flex justify-content-center loader">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>}

                <h2>WRITE A REVIEW</h2>

                {/* {!!this.state.lesson && <p style={{textAlign: 'center'}}>
                    Leave a review for <Link to={`/profiles/${this.state.lesson.student._id === this.props.user._id ? this.state.lesson.tutor._id : this.state.lesson.student._id}`}>
                        {this.state.lesson.student._id === this.props.user._id ? this.state.lesson.tutor.fullName : this.state.lesson.student.fullName}
                    </Link>
                </p>} */}

                
                {!!this.state.lesson && <form onSubmit={this.submit}>
                    <div className="mb-3">
                        <label className="form-label">Communication / Availability</label>
                        <SetRating 
                            rating={this.state.communication} 
                            onChange={(rating) => {this.setState({communication: rating})}} 
                        />
                    </div>

                    {this.state.lesson.student._id === this.props.user._id && <>
                        <div className="mb-3">
                            <label className="form-label">Understanding of the subject</label>
                            <SetRating 
                                rating={this.state.understanding} 
                                onChange={(rating) => {this.setState({understanding: rating})}} 
                            />
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Quality of interactivity</label>
                            <SetRating 
                                rating={this.state.interactivity} 
                                onChange={(rating) => {this.setState({interactivity: rating})}} 
                            />
                        </div>
                    </>}

                    {this.state.lesson.tutor._id === this.props.user._id && <>
                        <div className="mb-3">
                            <label className="form-label">Level attentiveness</label>
                            <SetRating 
                                rating={this.state.attentiveness} 
                                onChange={(rating) => {this.setState({attentiveness: rating})}} 
                            />
                        </div>
                    </>}
                    <div className="mb-3">
                        <label className="form-label">Write review</label>
                        <textarea className="input-simple" style={{height: 270}} type="text" placeholder="Write your feedback" onChange={(e) => {
                            this.setState({text: e.target.value})
                        }} />
                        {this.state.errors.filter((error) => error.param === 'text').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}
                    </div>

                    {this.state.errors.filter((error) => error.param === 'all').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}

                    <button className="button btn-filled" style={{margin: '0 auto', display: 'block'}} type="submit">Sumbit review</button>
                </form>}
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(
    mapStateToProps
)(withRouter(LeaveReview))