import React from 'react'
import MiniLogo from '../../assets/images/MiniLogo.svg'

class Avatar extends React.Component {
    render() {
        return <div className="subject-avatar" style={{width: this.props.size, height: this.props.size}}>
            {!!this.props.avatar.length && <img className="subject-avatar-img" src={this.props.avatar} style={{background: `#F7F7F7`}} />}
            {!this.props.avatar.length && <div className="subject-avatar-name" style={{background: `#F7F7F7`, fontSize: this.props.size/3}}>
                <img style={{width: '50%', objectFit: 'contain'}} src={MiniLogo} />
            </div>}
        </div>
    }
}

export default Avatar