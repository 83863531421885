import React from 'react'
import { withRouter } from "react-router-dom"
import SendIcon from '@material-ui/icons/Send';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as chatsActions from '../../store/actions/chats'
import chatsApi from '../../apis/chats';

let canTyping = true

class DialogItem extends React.Component {
    state = {
        text: ''
    }

    typing = (newText, prevText) => {
        if(newText > prevText && canTyping) {
            canTyping = false

            chatsApi.sendTyping({userId: this.props.userId})
            
            setTimeout(() => {
                canTyping = true
            }, 2500)
        }
    }

    render() {
        return <div className="chat-input">
            <textarea 
                className="col input-message" 
                id="input-message" 
                onKeyDown={(e) => {
                    if (e.keyCode === 13 && !e.shiftKey) {
                        e.preventDefault()
                        if(/\S/.test(this.state.text)) {
                            this.props.chatsActions.sendMessage({
                                text: this.state.text, 
                                userId: this.props.userId
                            })
                            
                            this.setState({text: ''})

                            let inputMessage = document.getElementById('input-message')
                            inputMessage.style.maxHeight = "50px";

                            let chat = document.getElementById('chat-messages')
                            chat.style.maxHeight = `calc(100vh - 230px)`
                        }
                    }
                }}
                onInput={() => {
                    this.props.focusPage()
                    let inputMessage = document.getElementById('input-message')
                    inputMessage.style.height = "5px";
                    inputMessage.style.height = (inputMessage.scrollHeight)+"px";

                    let chat = document.getElementById('chat-messages')

                    if(inputMessage.scrollHeight > 169) {
                        inputMessage.style.maxHeight = '169px'
                    } else {
                        inputMessage.style.maxHeight = (inputMessage.scrollHeight)+"px";
                    }

                    chat.style.maxHeight = `calc(100vh - ${321 + inputMessage.offsetHeight - 50}px)`

                    chat.scrollTop = chat.scrollTop + inputMessage.offsetHeight - 50
                }}
                onChange={(e) => {
                    this.typing(e.target.value, this.state.text)
                    this.setState({text: e.target.value})
                }}
                value={this.state.text}
            ></textarea>

            {!this.state.text && <div className="custom-placeholder">Write message...</div>}

            <button onClick={() => {
                if(/\S/.test(this.state.text)) {
                    this.props.chatsActions.sendMessage({
                        text: this.state.text, 
                        userId: this.props.userId
                    })
                    
                    this.setState({text: ''})

                    let inputMessage = document.getElementById('input-message')
                    inputMessage.style.maxHeight = "50px";

                    let chat = document.getElementById('chat-messages')
                    chat.style.maxHeight = `calc(100vh - 230px)`
                }
            }} className="button btn-filled"><SendIcon /></button>
        </div>
    }
}

function mapDispatchToProps(dispatch) {
    return {
        chatsActions: bindActionCreators(chatsActions, dispatch),
    }
}

export default connect(
    null,
    mapDispatchToProps
)(withRouter(DialogItem))