import React from 'react'
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

class SetRating extends React.Component {
    render() {
        return <div className="set-rating">
            <span onClick={() => this.props.onChange(1)}>
                {this.props.rating >= 1 && <StarIcon />}
                {this.props.rating < 1 && <StarBorderIcon />}
            </span>

            <span onClick={() => this.props.onChange(2)}>
                {this.props.rating >= 2 && <StarIcon />}
                {this.props.rating < 2 && <StarBorderIcon />}
            </span>

            <span onClick={() => this.props.onChange(3)}>
                {this.props.rating >= 3 && <StarIcon />}
                {this.props.rating < 3 && <StarBorderIcon />}
            </span>

            <span onClick={() => this.props.onChange(4)}>
                {this.props.rating >= 4 && <StarIcon />}
                {this.props.rating < 4 && <StarBorderIcon />}
            </span>

            <span onClick={() => this.props.onChange(5)}>
                {this.props.rating >= 5 && <StarIcon />}
                {this.props.rating < 5 && <StarBorderIcon />}
            </span>
        </div>
    }
}


export default SetRating