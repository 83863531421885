// User
export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGOUT = 'USER_LOGOUT'
export const USER_UPDATE_DESCRIPTION = 'USER_UPDATE_DESCRIPTION'
export const USER_UPDATE_AVATAR = 'USER_UPDATE_AVATAR'

// Chats 
export const GET_CHATS = 'GET_CHATS'
export const ADD_CHAT = 'ADD_CHAT'
export const GET_MESSAGES = 'GET_MESSAGES'
export const ADD_MESSAGE = 'ADD_MESSAGE'
export const SET_NO_READ = 'SET_NO_READ'
export const READ_MESSAGES = 'READ_MESSAGES'
export const SET_NO_READ_GLOBAL = 'SET_NO_READ_GLOBAL'
export const UPDATE_ONLINE = 'UPDATE_ONLINE'
export const SET_TYPING = 'SET_TYPING'