import React from 'react'
import authApi from '../../../apis/auth'
import countries from "countries-list";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as userActions from '../../../store/actions/user'
import { Link, withRouter } from "react-router-dom"
import { getTimezoneOffset } from '../../../controllers/DateController';
import AuthTutorImg from '../../../assets/images/auth-tutor.svg'
import CheckBox from '../../../components/inputs/CheckBox';
import Select from '../../../components/inputs/Select';

const countryCodes = Object.keys(countries.countries);
const countryNames = countryCodes.map(code => countries.countries[code].name);

class Step2Student extends React.Component {
    state = {
        type: 'tutor',
        fullName: '',
        email: '',
        password: '',
        passwordConfirm: '',
        country: 'United Kingdom',
        privacy: false,
        errors: []
    }

    submit = (e) => {
        e.preventDefault();

        if(this.state.isFetching)
            return

        this.setState({isFetching: true})
        
        authApi.register({
            email: this.state.email.toLowerCase().replace(/\s+/g, ''),
            password: this.state.password,
            passwordConfirm: this.state.passwordConfirm,
            fullName: this.state.fullName,
            country: this.state.country,
            type: this.state.type,
            privacy: this.state.privacy,
            gmt: getTimezoneOffset()
        }).then(response => {
            if(response.success) {
                this.props.userActions.loginUser(response.user, response.token)
                if(!response.user.isConfirmed) {
                    this.props.history.push('/confirm')
                }
            } else {
                this.setState({errors: response.errors})
            }
            this.setState({isFetching: false})
        })
    }

    render() {
        return <>

            <h2 style={{marginBottom: 15}}>TUTOR</h2>

            <h3>Create your account</h3>

            <div style={{flexDirection: 'row', display: 'flex'}} className="register-block">
                <div style={{display: 'flex'}} className="d-none d-md-block">
                    <img src={AuthTutorImg} />
                </div>
                <div style={{width: 150}}></div>
                <div className="auth-card">
                    {this.state.isFetching && <div className="d-flex justify-content-center loader">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>}

                    <form onSubmit={this.submit}>
                        <div className="mb-0">
                            <input className="input-auth" type="text" placeholder="Full Name" onChange={(e) => {
                                this.setState({fullName: e.target.value})
                            }} />
                            {this.state.errors.filter((error) => error.param === 'fullName').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}
                        </div>

                        <div className="mb-0">
                            <Select value={this.state.country} onClick={(value) => {
                                this.setState({country: value})
                            }} values={
                                [
                                    {label: 'United Kingdom', value: 'United Kingdom'},
                                    {label: 'United States Of America', value: 'United States Of America'},
                                    ...countryNames.map(x => ({label: x, value: x}))
                                    .filter(x => x.value !== 'United Kingdom' || x.value !== 'United States Of America')
                                ]
                            } />
                            {this.state.errors.filter((error) => error.param === 'country').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}
                        </div>

                        <div className="mb-0">
                            <input className="input-auth" type="text" placeholder="Email" onChange={(e) => {
                                this.setState({email: e.target.value})
                            }} />
                            {this.state.errors.filter((error) => error.param === 'email').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}
                        </div>

                        <div className="mb-0">
                            <input className="input-auth" type="password" placeholder="Password" onChange={(e) => {
                                this.setState({password: e.target.value})
                            }} />
                            {this.state.errors.filter((error) => error.param === 'password').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}
                        </div>

                        <div className="mb-0">
                            <input className="input-auth" type="password" placeholder="Confirm password" onChange={(e) => {
                                this.setState({passwordConfirm: e.target.value})
                            }} />
                            {this.state.errors.filter((error) => error.param === 'passwordConfirm').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}
                        </div>

                        {this.state.errors.filter((error) => error.param === 'all').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}

                        <button className="button btn-full" type="submit">Register</button>

                        <CheckBox onClick={(e) => {
                            this.setState({privacy: !this.state.privacy})
                        }} label={<p>I accept all the <a href="/privacy" target="_blank" onClick={(e) => {
                            e.stopPropagation()
                        }}>Privacy & Terms</a> of use</p>} />
                        {this.state.errors.filter((error) => error.param === 'privacy').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}

                        <div className="add-link">Already have an account? <span onClick={() => {
                            this.props.history.push('/login')
                        }}>Login</span></div>
                    </form>
                </div>
                
            </div>
        </>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Step2Student))