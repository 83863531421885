import io from 'socket.io-client'
import store from '../store/index'
import * as StoreConstants from '../store/types'
import { scrollChatToBottomIfBottom } from './FunctionsController'

// import {playNewMessage, stopBeep, playRington, stopRington} from './SoundController'

let socket = false

const SocketController = { 
    init: (_token) => {
        if(socket) 
            return

        let counterConnect = 0
    
        socket = io(`${process.env.REACT_APP_API_URL}`, {transports: ['websocket', 'polling', 'flashsocket']})
        
        socket.on('connect', () => {
            if(counterConnect > 0)
                window.location.reload()

            socket.emit('auth', _token)
            counterConnect++
        })

        socket.on('sendMessage', ({message, otherUserId}) => {
            let chat = store.getState().chats.chats.find(chat => chat.user._id === otherUserId)

            if(!!chat) {
                if(window.location.pathname === `/chats/${otherUserId}`)
                    scrollChatToBottomIfBottom()

                store.dispatch({
                    type: StoreConstants.SET_NO_READ_GLOBAL,
                    payload: chat.noRead === 0 ? store.getState().user.noRead + 1 : store.getState().user.noRead
                })
    
                store.dispatch({
                    type: StoreConstants.SET_NO_READ,
                    payload: {userId: otherUserId, noRead: chat.noRead + 1}
                })

                store.dispatch({
                    type: StoreConstants.ADD_MESSAGE,
                    payload: {message, userId: otherUserId, myId: store.getState().user._id}
                })
            } else {
                let chat = {
                    ...message.chat,
                    user: message.user,
                    lastMessage: message,
                    isGetted: false,
                    isPreFetching: false,
                    isTyping: false,
                    updatedAt: Date.now(),
                    messages: [],
                    noRead: message.chat.noRead + 1
                }

                store.dispatch({
                    type: StoreConstants.SET_NO_READ_GLOBAL,
                    payload: message.chat.noRead === 0 ? store.getState().user.noRead + 1 : store.getState().user.noRead
                })
                
                if(store.getState().chats.isGetted)
                    store.dispatch({
                        type: StoreConstants.ADD_CHAT,
                        payload: {chat}
                    })
            }
        })

        socket.on('readMessages', ({otherUserId, userId}) => {
            if(store.getState().user._id === userId) {
                let chat = store.getState().chats.chats.find(chat => chat.user._id === otherUserId)
                
                store.dispatch({
                    type: StoreConstants.SET_NO_READ_GLOBAL,
                    payload: chat.noRead > 0 && store.getState().user.noRead > 0 ? store.getState().user.noRead - 1 : 0
                })
                store.dispatch({
                    type: StoreConstants.SET_NO_READ,
                    payload: {userId: otherUserId, noRead: 0}
                })

                if(!!chat) {
                    store.dispatch({
                        type: StoreConstants.READ_MESSAGES,
                        payload: {userId: userId, myId: otherUserId}
                    })        
                }
            } else {
                let chat = store.getState().chats.chats.find(chat => chat.user._id === userId)

                if(!!chat) {
                    store.dispatch({
                        type: StoreConstants.READ_MESSAGES,
                        payload: {userId, myId: userId}
                    }) 
                    
                    store.dispatch({
                        type: StoreConstants.UPDATE_ONLINE,
                        payload: {userId: otherUserId, online: {isOnline: true, onlineAt: Date.now()}}
                    })
        
                    store.dispatch({
                        type: StoreConstants.SET_TYPING,
                        payload: {userId, isTyping: false}
                    })
                }
            }
        })

        socket.on('sendTyping', ({userId}) => {
            let chat = store.getState().chats.chats.find(chat => chat.user._id === userId)

            if(!!chat) {
                store.dispatch({
                    type: StoreConstants.UPDATE_ONLINE,
                    payload: {userId, online: {isOnline: true, onlineAt: Date.now()}}
                })
    
                store.dispatch({
                    type: StoreConstants.SET_TYPING,
                    payload: {userId, isTyping: true}
                })

                setTimeout(() => {
                    store.dispatch({
                        type: StoreConstants.SET_TYPING,
                        payload: {userId, isTyping: false}
                    })
                }, 2500);
            }
        })
    },
    getSocketId: () => {
        return socket.id
    },
    disconnect: () => {
        if(socket)
            socket.disconnect()
    }
}

export default SocketController
