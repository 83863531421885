import React from 'react'
import authApi from '../../apis/auth'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom"
import AuthConfirmImg from '../../assets/images/auth-confirm.svg'

const steps = 3

class Confirm extends React.Component {
    state = {
        code: '',
        errors: [],
        isFetching: false,
        step: 3,
        resend: false
    }

    submit = (e) => {
        e.preventDefault();

        if(this.state.isFetching)
            return

        this.setState({isFetching: true})
        
        authApi.confirm({
            code: this.state.code
        }).then(response => {
            if(response.success) {
                window.location.href = '/create-profile'
            } else {
                this.setState({errors: response.errors})
            }

            this.setState({isFetching: false})
        })
    }

    render() {
        return <div className="auth-block container confirm-block">
            <div className="steps">
                {Array.from(Array(steps).keys()).map((step) => {
                    let active = this.state.step >= (step+1)
                    let firstLine = step !== 0
                    let lastLine = step+1 !== steps
                    return <>
                        {firstLine && <span className={`line first ${active ? 'active' : ''}`}></span>}
                        <div className={`step ${active ? 'active': ''}`}>
                            {step+1}
                            {active && <span className="circle"></span>}
                        </div>
                        {lastLine && <span className={`line last ${active ? 'active' : ''}`}></span>}
                    </>
                })}
            </div>

            <div className="auth-card">
                {this.state.isFetching && <div className="d-flex justify-content-center loader">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>}

                <img src={AuthConfirmImg} />

                <h2>EMAIL CONFIRMATION</h2>

                <p style={{textAlign: 'center', color: '#4D4D4D', fontSize: '25px'}}>
                    We have sent an email to <span style={{color: 'var(--main-color)'}}>{this.props.user.email}</span>. Once you confirm, we'll get you registered as a {this.props.user.type === 'student' ? 'Student' : 'Tutor'} :)
                </p>
                
                <form onSubmit={this.submit}>
                    <div className="mb-0">
                        <input className="input-auth" type="text" placeholder="Code" onChange={(e) => {
                            this.setState({code: e.target.value})
                        }} />
                        {this.state.errors.filter((error) => error.param === 'code').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}
                    </div>

                    {this.state.errors.filter((error) => error.param === 'all').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}

                    <button className="button btn-full" type="submit">Confirm</button>
                </form>
                {!this.state.resend && <p style={{textAlign: 'center', color: '#4D4D4D', fontSize: '25px', marginTop: 10}}>
                    If you didn't receive the email <a style={{color: 'var(--main-color)', cursor: 'pointer'}} onClick={(e) => {
                        e.preventDefault()
                        this.setState({isFetching: true})
                        authApi.resend().then(response => {
                            if(response.success) {
                                this.setState({resend: true})
                            }
                            this.setState({isFetching: false})
                        })
                    }}>Click to resend the confirmation</a>
                </p>}
                {this.state.resend && <p style={{textAlign: 'center', color: '#4D4D4D', fontSize: '25px', marginTop: 10}}>
                    We sent you another mail!
                </p>}
                <p style={{textAlign: 'center', color: '#4D4D4D', fontSize: '25px', marginTop: 10}}>
                    (check your spam folder too!)
                </p>
                
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(
    mapStateToProps
)(withRouter(Confirm))