import React from 'react'
import countries from "countries-list";
import categoryApi from '../../apis/category'
import qs from 'qs';
import { withRouter } from 'react-router-dom';
import SimpleSelect from '../inputs/SimpleSelect';

const countryCodes = Object.keys(countries.countries);
const countryNames = countryCodes.map(code => countries.countries[code].name);

const prices = [
    'All',
    '0$ - 5$',
    '5$ - 10$',
    '10$ - 20$',
    '20$ - 30$',
    '30$ - 50$',
    '50$+'
]

let waitSearchQ = false

class TutorFilters extends React.Component {
    state = {
        q: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).q || '',
        country: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).country || '',
        category: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).category || '',
        categories: [],
        price: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).price || 0
    }

    componentDidMount() {
        this.props.onFilter(this.state)
        categoryApi.getCategories({}).then((response) => {
            if(response.success) {
                this.setState({
                    categories: response.categories.sort(function(a, b){
                        if(a.name < b.name) { return -1; }
                        if(a.name > b.name) { return 1; }
                        return 0;
                    })
                })
            }
        })
    }

    setGetParams = () => {
        let searchParameters = new URLSearchParams()
                            
        if(this.state.category)
            searchParameters.append('category', this.state.category)

        if(this.state.country)
            searchParameters.append('country', this.state.country)

        if(this.state.price)
            searchParameters.append('price', this.state.price)

        if(this.state.q)
            searchParameters.append('q', this.state.q)

        this.props.history.push({
            search: `?${searchParameters}`
        })
    }

    render() {
        return <div className="tutor-filters col-12 col-md-12 col-lg-12 row">
                <div className="col-md-12 row" style={{margin: 0, padding: 0, marginBottom: 20}}>
                    <div className="col">
                        <input 
                            value={this.state.q}
                            onChange={(e) => {
                                this.setState({q: e.target.value})
                                
                                if(waitSearchQ) {
                                    clearTimeout(waitSearchQ)
                                }

                                waitSearchQ = setTimeout(() => {
                                    this.setGetParams()
                                    this.props.onFilter(this.state)
                                }, 300);
                            }} 
                            placeholder="What would you like to learn?"
                            className="input-simple w-100"
                            type="text"
                        />
                    </div>                    
                </div>
                <div className="col-md-4 row">
                    <div className="col-auto">
                        <label className="col-form-label">Study area</label>
                    </div>
                    <div className="col">
                        <SimpleSelect 
                            value={this.state.category}
                            values={[
                                {value: '', label: 'All'}, 
                                ...this.state.categories.map(category => ({value: category._id, label: category.name}))
                            ]} 
                            onClick={(value) => {
                                this.setState({category: value}, () => {
                                    this.setGetParams()
                                    this.props.onFilter(this.state)
                                })
                            }} 
                        />
                    </div>                    
                </div>
                <div className="col-md-4 row">
                    <div className="col-auto">
                        <label className="col-form-label">Country</label>
                    </div>
                    <div className="col">
                        <SimpleSelect 
                            value={this.state.country}
                            values={[
                                {value: '', label: 'All'}, 
                                ...countryNames.map(country => ({value: country, label: country}))
                            ]} 
                            onClick={(value) => {
                                this.setState({country: value}, () => {
                                    this.setGetParams()
                                    this.props.onFilter(this.state)
                                })
                            }} 
                        />
                    </div> 
                </div>
                <div className="col-md-4 row">
                    <div className="col-auto">
                        <label className="col-form-label">Price</label>
                    </div>
                    <div className="col">
                        <SimpleSelect 
                            value={this.state.price}
                            values={prices.map((price, index) => ({value: index, label: price}))} 
                            onClick={(value) => {
                                this.setState({price: value}, () => {
                                    this.setGetParams()
                                    this.props.onFilter(this.state)
                                })
                            }} 
                        />
                    </div> 
                </div>
        </div>
    }
}

export default withRouter(TutorFilters)