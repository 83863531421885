import './assets/styles/bootstrap.css'
import './assets/styles/globals.css'
import './assets/styles/auth.css'
import React from 'react'
import AppRouter from './router'
import MainLayout from './layouts/Main'
import { connect } from 'react-redux'
import Ban from './pages/Ban'
// import Modals from './Common/Modals'
import 'simplebar/dist/simplebar.min.css';

class App extends React.Component {
    render() {
        return <>
            {((this.props.user.isAuth && !this.props.user.isBan) || !this.props.user.isAuth) && <MainLayout>
                <AppRouter />
            </MainLayout>}
            {this.props.user.isAuth && this.props.user.isBan && <Ban />}
            {/* <Modals /> */}
        </>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(
    mapStateToProps
)(App)
