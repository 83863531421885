import React from 'react'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const faqs = [
    {
        title: 'Basics',
        questions: [
            {
                title: 'What type of tutors does The Tutor Link use?',
                answer: `The Tutor Link uses young experienced tutors who have just left school or university, this allows them to share their recent experience and the knowledge they have learnt through their studies of specific subjects. 
                
                All our tutors are professional as well as vetted.`
            },
            {
                title: 'How do I find a tutor on The Tutor Link? ',
                answer: `To find a tutor on The Tutor Link you simply have to register a student and search for the subjects you are looking to be tutored for, here we will provide matches according to your personal needs and you will get a free intro session with your tutor.`
            },
            {
                title: `What happens if I'm not satisfied with my tutor?`,
                answer: `If you are not satisfied with your tutor you can report directly back to us with your feedback and we can either refund you or match you with a new tutor.`
            },
        ]
    },
    {
        title: 'How It Works',
        questions: [
            {
                title: 'How long do tutoring sessions last?',
                answer: `The tutoring session will normally have a one-hour duration or this can change according to the amount of time allocated by your tutor.`
            },
            {
                title: 'How do I pay for the tutoring?',
                answer: `You can pay for your tutoring via online card payment through PayPal.`
            },
            {
                title: `How much does the tutoring cost? `,
                answer: `The cost of your tutoring will vary according to the prices set by the tutor for the subject of learning.`
            },
            {
                title: `How many tutoring sessions will I get? `,
                answer: `A standard 121 sessions are included with your tutoring package; these lessons are dedicated to improving your child's learning in a specific subject.`
            },
            {
                title: `What subjects do The Tutor Link cover? `,
                answer: `The Tutor Link covers a wide range of subjects, from Maths, English, Science and History to more specialised subjects such as Psychology.`
            },
            {
                title: `How do the tutoring sessions take place?`,
                answer: `Once you have signed up and chosen your subject/tutor, the 1-1 sessions will take place via a zoom call.`
            },
        ]
    },
    {
        title: 'Support ',
        questions: [
            {
                title: 'How to report technical difficulties? ',
                answer: `To report technical difficulties when using the tutoring system you can simply contact us. 
                Always make sure you are using the latest version of zoom for video calls and your latest browser to function our site properly.`
            },
            {
                title: 'How do I cancel my student membership?',
                answer: `If you would like to cancel your student account simply go to your account and click delete.`
            },
        ]
    }
]

const faqsstudent = [
    {
        title: 'Basics',
        questions: [
            {
                title: 'How does the online tutoring system work? ',
                answer: `Our online tutoring system operates for young students who are looking to earn an extra income through teaching their skills to younger students, you simply sign up, choose your area of expertise and earn money while helping younger students develop their learning.`
            },
            {
                title: 'What do I need to become a tutor?',
                answer: `To become a tutor on The Tutor Link you must make sure you have a good internet connection, as well as a high-quality webcam and microphone. For qualifications, you simply need to have experience in the area you are teaching but tutors that have extra certificates and qualifications are more likely to get chosen.You can upload any extra credentials to your tutor profile.`
            },
            {
                title: `Where do the students come from?`,
                answer: `Our students come from all over the place via our marketing. We aim to help students who are in younger learning and would like tutoring but might not be able to financially afford it through the traditional tutoring system.`
            },
            {
                title: `How much could I earn with The Tutor Link?`,
                answer: `The sky is your limit! The Tutor Link allows you to set when you want to teach and how long for, as well as your price. In total, you will teach 121 sessions to your student over a period.`
            },
            {
                title: `Can I choose when my lessons happen? `,
                answer: `Yes, with our tutoring system you get to decide your hours and time slots available for when you want to teach, giving you full flexibility over your working hours.`
            },
        ]
    },
    {
        title: 'How It Works',
        questions: [
            {
                title: 'What about teaching in different time zones? ',
                answer: `All the scheduled lessons you have with your students will be listed in your local time zone to avoid any confusion, you can block off time slots for when you are not working.`
            },
            {
                title: 'How do I sign up to be a tutor?',
                answer: `To sign up to become a tutor you simply have to register and we will send you an email for you to build your profile, we will then review and you can start tutoring right away once approved.`
            },
            {
                title: `How do I schedule a lesson with a student?`,
                answer: `To schedule a lesson with your student you simply have to book your timeslot and prepare any online materials to send to your student in advance, your student will then get an email with a link to click on that will take them to the live call when time.`
            },
            {
                title: `What happens if a student does not show up for a session?`,
                answer: `This depends on the policies that you have set for your tutoring, you will have the option to put up a cancellation notice, if the student fails to cancel before this set time then you will still get credited.`
            },
            {
                title: `How do I get paid for tutoring?`,
                answer: `You will get paid for your tutoring sessions directly into your PayPal or bank account.`
            },
        ]
    },
    {
        title: 'Support ',
        questions: [
            {
                title: 'How much do I have to pay to be a tutor on The Tutor Link?',
                answer: `You do not have to pay to be a tutor on our platform but we do take a small percentage from the tutoring fee for you to teach on our platform.`
            },
            {
                title: 'What happens if a student is not satisfied?',
                answer: `If a student is not satisfied they have 24 hours to report to us after a session, we will then review the circumstances and the student will be refunded if the tutoring was found to be unsatisfactory.`
            },
            {
                title: 'How much do you charge per session?',
                answer: `The rate of your tutoring sessions is set by you according to what you will teach and the duration.`
            },
            {
                title: 'How will I find my first student?',
                answer: `A student will contact you for tutoring if they find your profile to be a match to the subjects they want to learn, you can then accept with an introductory session.`
            },
        ]
    }
]

class FAQ extends React.Component {
    state = {
        activeNum: 0
    }
    
    render() {
        let answerNum = 0

        return <div className="faq container">
            <h2>Frequently Asked Questions</h2>
            <h3 className="text-center">Student FAQ</h3>

            <div className="faqs">
                {faqs.map(faq => {
                    return <div className="questions">
                        <h4>{faq.title}</h4>

                        {faq.questions.map(question => {
                            answerNum++;
                            let currNum = answerNum
                            return <div className={`question  ${currNum === this.state.activeNum ? 'active' : ''}`} onClick={() => {
                                if(this.state.activeNum === currNum) {
                                    this.setState({activeNum: 0})
                                } else {
                                    this.setState({activeNum: currNum})
                                }
                            }}>
                                <div className="question-title">
                                    {question.title}
                                    <KeyboardArrowUpIcon />
                                </div>

                                <div className={`question-answer`}>{question.answer}</div>
                            </div>
                        })}
                    </div>
                })}
            </div>

            <h3 className="text-center mt-5">Tutor's FAQ</h3>

            <div className="faqs">
                {faqsstudent.map(faqsstudent => {
                    return <div className="questions">
                        <h4>{faqsstudent.title}</h4>

                        {faqsstudent.questions.map(question => {
                            answerNum++;
                            let currNum = answerNum
                            return <div className={`question  ${currNum === this.state.activeNum ? 'active' : ''}`} onClick={() => {
                                if(this.state.activeNum === currNum) {
                                    this.setState({activeNum: 0})
                                } else {
                                    this.setState({activeNum: currNum})
                                }
                            }}>
                                <div className="question-title">
                                    {question.title}
                                    <KeyboardArrowUpIcon />
                                </div>

                                <div className={`question-answer`}>{question.answer}</div>
                            </div>
                        })}
                    </div>
                })}
            </div>
        </div>
    }
}

export default FAQ