import { handleError, handleSuccess } from "."
import CookieController from "../controllers/CookieController"

let apiUrl = `${process.env.REACT_APP_API_URL}/api/lesson/`

const apis = {
    bookLesson: function(params) {
        return fetch(`${apiUrl}book-lesson`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${CookieController.get('_token')}`
            },
            body: JSON.stringify(params)
        })
        .then(handleSuccess)
        .catch(e => {
            console.error('lesson api "bookLesson" error:', e)
            return handleError()
        })
    },
    getList: function(params) {
        let searchParameters = new URLSearchParams()
            
        Object.keys(params).forEach(function(parameterName) {
            searchParameters.append(parameterName, params[parameterName])
        })

        return fetch(`${apiUrl}get-list?${searchParameters}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${CookieController.get('_token')}`
            }
        })
        .then(handleSuccess)
        .catch(e => {
            console.error('lesson api "getList" error:', e)
            return handleError()
        })
    },
    cancel: function(params) {
        return fetch(`${apiUrl}cancel/${params._id}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${CookieController.get('_token')}`
            }
        })
        .then(handleSuccess)
        .catch(e => {
            console.error('lesson api "cancel" error:', e)
            return handleError()
        })
    },
    changeTime: function(params) {
        return fetch(`${apiUrl}change-time/${params._id}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${CookieController.get('_token')}`
            },
            body: JSON.stringify(params)
        })
        .then(handleSuccess)
        .catch(e => {
            console.error('lesson api "changeTime" error:', e)
            return handleError()
        })
    },
    get: function(params) {
        return fetch(`${apiUrl}get/${params._id}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${CookieController.get('_token')}`
            }
        })
        .then(handleSuccess)
        .catch(e => {
            console.error('lesson api "get" error:', e)
            return handleError()
        })
    },
    checkTrial: function(params) {
        return fetch(`${apiUrl}check-trial/${params.subjectId}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${CookieController.get('_token')}`
            }
        })
        .then(handleSuccess)
        .catch(e => {
            console.error('lesson api "checkTrial" error:', e)
            return handleError()
        })
    },
    bookTrialLesson: function(params) {
        return fetch(`${apiUrl}book-trial-lesson`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${CookieController.get('_token')}`
            },
            body: JSON.stringify(params)
        })
        .then(handleSuccess)
        .catch(e => {
            console.error('lesson api "bookTrialLesson" error:', e)
            return handleError()
        })
    },
}

export default apis