import React from 'react'

class Privacy extends React.Component {
    render() {
        return <div className="container privacy">
            <h1>TERMS & CONDITIONS</h1>

            <p>
            TheTutor.Link Limited (TheTutor.Link, we, us or our) are an online tutoring portal where Members, both Students and Tutors (terms defined below), can collaborate with each other and take advantage of the Services. These Services are accessible at TheTutor.Link and any other websites where TheTutor.Link makes the Services available (collectively, the Site).
            </p>
            <p>
            This Agreement is between you and TheTutor.Link. If you are using the Site or the Services, these terms of service (Terms) are between you and TheTutor.Link. In providing certain Payment Services, TheTutor.Link will handle any and all payments in and withdrawals conducted through or in connection with the Site or Services, which shall be subject to the Payment terms of service available at TheTutor.Link.
            </p>
            <p>
            The Site is not available to (a) any person under the age of 16, (b) any person who has been suspended or removed from the Site by TheTutor.Link and (c) any person whose registration of an Account or use of the Site or Services would constitute a violation of any laws and regulations.
            </p>
            <p>
            By registering an Account or otherwise using the Site or the Services, you represent that (a) you are at least 18 years of age; (b) you have not been previously suspended or removed from the Site by TheTutor.Link; and (c) you are in compliance with all applicable laws and regulations.
            </p>
            <p>
            If you are a User and are under the age of 16 (Child User), you may not register an Account or otherwise use the Site or the Services without the consent and approval of your legal parent or guardian, as applicable (Parent).
            </p>
            <p>
            Any new user that begins the registration process for themselves without a parent present may have the registration process restricted until TheTutor.Link is satisfied that the parent has approved or assumed responsibility for the child's account.
            </p>
            <p>
            Furthermore, a TheTutor.Link Child User may use the Site or the Services if registered through an educational organisation, where TheTutor.Link is satisfied the Parent of such Child User has consented to such Child User’s use of the Site or the Services.
            </p>
            <p>
            A Child User that has registered an Account (whether through such external educational organisations or otherwise) will only be permitted to use the Site and/or the Services for so long as TheTutor.Link is satisfied that such use has been consented to by the Child User’s Parent. In any event, a Child User shall not be able to use the Site and/or the Services if their use violates any law or regulation.
            </p>
            <p>
            If you are the parent (or claim to be a parent) of a child seeking to register as a child user of the website, you hereby declare and warrant that you are the legal parent or guardian of such child user, and you agree to be subject to these terms on behalf of such child user (And any other applicable policies), including but not limited to being responsible for all use of this website or service by child users.
            </p>
            <p>
            You acknowledge that TheTutor.Link can choose, but is not obligated, to directly or through a third party conduct any inquiries that TheTutor.Link deems necessary to verify your registration information, including but not limited to hiring a third party to provide verification services. TheTutor.Link reserves all rights to take legal action against anyone who misrepresents personal information or whose identity is untrue.
            </p>
            <p>
            In these terms: Account refers to the account opened in TheTutor.Link when you registered as a member and used the service; Claim means any lawsuit, claim, lawsuit, lawsuit, demand or lawsuit
            </p>
            <p>
            arising from or related to these terms, websites or services, whether based on contract, tort, negligence or any other theory of liability; The connection service has the meaning given in Article 6.2; Content refers to all content (except user content) provided by TheTutor.Link through the website, service or related promotions and official social media channels, including all content licensed from third parties (if any); Credit has the meaning given in the payment terms; The content of integrated services and IS has the meaning given in Article 7.4(a); A member refers to an individual or legal entity that registered an account; The payment service has the meaning given in the payment terms; The payment terms have the meaning given in Article 1;
            </p>
            <p>
            The policy has the meaning given in Article 4.1; Prohibited content has the meaning given in Article 9.4(e)(vi); Service refers to any connection service or payment service provided by TheTutor.Link; Students refer to members who purchase counseling services; Counselors refer to members who provide or provide and provide counseling services; Counselling services refer to all services provided by counselors, including paid courses, packages, and other services provided during the exchange of credits; User refers to (1) a member who uses this website on their own behalf, or (2) a person who uses this website on behalf of a member of a company or organization;
            </p>
            <p>
            User content refers to all content posted, uploaded, published, submitted, transmitted, or included in its membership profile or TheTutor.Link promotional activities by the user, which will be provided through the website or service, including any IS content; and Where applicable, you mean (1) a visitor (non-member or user) of this website, (2) a member who visits this website or uses the service in his own name; (3) if the service is used on behalf of a company or organization , The member who uses the service and the user who visits the website on behalf of the member (subject to Article 4.6).
            </p>
        </div>
    }
}

export default Privacy