import React from 'react'
import authApi from '../../apis/auth'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as userActions from '../../store/actions/user'
import { withRouter } from "react-router-dom"

class Login extends React.Component {
    state = {
        email: '',
        errors: [],
        isFetching: false,
        success: false
    }

    submit = (e) => {
        e.preventDefault();

        if(this.state.isFetching)
            return
        
        this.setState({isFetching: true})

        authApi.forgot({
            email: this.state.email.toLowerCase().replace(/\s+/g, ''),
        }).then(response => {
            if(response.success) {
                this.setState({success: true})
            } else {
                this.setState({errors: response.errors})
            }

            this.setState({isFetching: false})
        })
    }

    render() {
        return <div className="auth-block">
            {!this.state.success && <div className="auth-card">
                {this.state.isFetching && <div className="d-flex justify-content-center loader">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>}
                
                <h2>FORGOT PASSWORD</h2>
                
                <form onSubmit={this.submit}>
                    <div className="mb-0">
                        <input className="input-auth" type="text" placeholder="Email" onChange={(e) => {
                            this.setState({email: e.target.value})
                        }} />
                        {this.state.errors.filter((error) => error.param === 'email').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}
                    </div>

                    {this.state.errors.filter((error) => error.param === 'all').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}

                    <button className="button btn-full" type="submit">Restore</button>
                </form>
            </div>}

            {this.state.success && <div className="auth-card">
                <h2>FORGOT PASSWORD</h2>

                <p style={{textAlign: 'center', color: '#6C6C6C', fontWeight: '600'}}>
                    Check your email and follow reset password link.
                </p>
            </div>}
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Login))