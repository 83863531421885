import { handleError, handleSuccess } from "."
import CookieController from "../controllers/CookieController"

let apiUrl = `${process.env.REACT_APP_API_URL}/api/tutor/`

const apis = {
    getList: function(params) {
        let searchParameters = new URLSearchParams()
            
        Object.keys(params).forEach(function(parameterName) {
            searchParameters.append(parameterName, params[parameterName])
        })

        return fetch(`${apiUrl}get-list?${searchParameters}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
        .then(handleSuccess)
        .catch(e => {
            console.error('tutor api "getList" error:', e)
            return handleError()
        })
    },
    createLesson: function(formData) {
        return fetch(`${apiUrl}create-lesson`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${CookieController.get('_token')}`
            },
            body: formData
        })
        .then(handleSuccess)
        .catch(e => {
            console.error('tutor api "createLesson" error:', e)
            return handleError()
        })
    },
    editLesson: function(formData) {
        return fetch(`${apiUrl}edit-lesson`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${CookieController.get('_token')}`
            },
            body: formData
        })
        .then(handleSuccess)
        .catch(e => {
            console.error('tutor api "editLesson" error:', e)
            return handleError()
        })
    },
    getLesson: function(params) {
        return fetch(`${apiUrl}get-lesson/${params._id}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${CookieController.get('_token')}`
            }
        })
        .then(handleSuccess)
        .catch(e => {
            console.error('tutor api "getLesson" error:', e)
            return handleError()
        })
    },
    getSubjects: function(params) {
        let searchParameters = new URLSearchParams()
            
        Object.keys(params).forEach(function(parameterName) {
            searchParameters.append(parameterName, params[parameterName])
        })

        return fetch(`${apiUrl}get-subjects?${searchParameters}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
        .then(handleSuccess)
        .catch(e => {
            console.error('tutor api "getSubjects" error:', e)
            return handleError()
        })
    },
}

export default apis