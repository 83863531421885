import React from 'react'

class Contact extends React.Component {
    render() {
        return <div className="contact">

            <section className="hero with-pattern py-5 bg-light">
                <div className=" py-5 my-5">
                    <div className="row">
                    <div className="col-md-7 mx-auto text-center">
                        <p className="h6 text-uppercase text-primary">Contact us</p>
                        <h1>We’d love to hear from you</h1>
                        {/* <p className="text-muted">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p> */}
                    </div>
                    </div>
                </div>
            </section>

            <section className="pulled-up-section">
            <div className="container text-center">
                <div className="card shadow ">
                <div className="card-body py-5 p-md-5 index-forward">
                    <div className="row">
                    <div className="col-md-6 mb-4 mb-lg-0">
                        <div className="icon-block icon-block-lg mb-4 mx-auto">
                            <i class="fa fa-phone-square fa-3x" aria-hidden="true"></i>
                        </div>
                        <h3 className="h4">Phone</h3>
                        <ul className="list-unstyled mb-0">
                            <li className="text-muted">
                            <a class="reset-anchor" href="tel:07305523333">0730 552 3333</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-6 mb-4 mb-lg-0">
                        <div className="icon-block icon-block-lg mb-4 mx-auto">
                            <i class="fa fa-envelope-o fa-3x" aria-hidden="true"></i>
                        </div>
                        <h3 className="h4">Support</h3>
                        <ul className="list-unstyled mb-0">
                            <li className="text-muted">
                            <a className="reset-anchor" href="mailto:hey@thetutor.link">hey@thetutor.link</a>
                            </li>
                        
                        </ul>
                    </div>
                    {/* <div className="col-md-4">
                        <div className="icon-block icon-block-lg mb-4 mx-auto">
                            <i class="fa fa-map-marker fa-3x" aria-hidden="true"></i>
                        </div>
                        <h3 className="h4">Location</h3>
                        <ul className="list-unstyled mb-0 px-5">
                        <li className="text-muted"><a className="reset-anchor" href="#">1234 New Ave, New York, NY00235, USA</a></li>
                        </ul>
                    </div> */}
                    </div>
                </div>
                </div>
            </div>
            </section>
{/* 
            <section class="hero py-5">
                <div class="container py-5">
                    <div class="row">
                    <div class="col-lg-7 mx-auto text-center mb-5">
                        <h2>Contact Us</h2>
                    </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 mx-auto">
                            <form action="#">
                            <div class="row">
                                <div class="form-group col-lg-4 col-md-6">
                                <input class="form-control py-4" type="text" placeholder="Full name" />
                                </div>
                                <div class="form-group col-lg-4 col-md-6">
                                <input class="form-control py-4" type="email" placeholder="Email address" />
                                </div>
                                <div class="form-group col-lg-4">
                                <input class="form-control py-4" type="text" placeholder="Subject" />
                                </div>
                                <div class="form-group col-12">
                                <textarea class="form-control" rows="5" placeholder="How can we help you"></textarea>
                                </div>
                                <div class="form-group col-12 text-md-center mb-0">
                                <button class="button btn-filled" type="submit"> <i class="fas fa-paper-plane mr-2"></i>Send message</button>
                                </div>
                            </div>
                            </form>
                        </div>
                        </div>
                </div>
            </section> */}

           






        </div>
    }
}

export default Contact
