import countries from 'countries-list';
import qs from 'qs';
import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getTimezoneOffset } from '../../controllers/DateController';
import Reviews from '../review/Reviews';
import BookModal from '../tutor/BookModal';
import Rating from '../tutor/Rating';
import Schedule from '../tutor/Schedule';
import Avatar from './Avatar';
import Subjects from '../subject/Subjects'
import SubjectModal from '../subject/SubjectModal';

class OtherProfile extends React.Component {
    constructor(props) {
        super(props)
        this.scheduleSection = React.createRef()  
    }

    state = {
        part: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).part,
        bookModal: false,
        subjectModal: false
    }

    bookLesson = (clickTime) => {
        this.setState({
            bookModal: {
                time: clickTime,
                user: this.props.user,
                subject: this.state.subjectModal
            },
            subjectModal: false
        })
    }

    render() {
        return <div className="container">
            {!!this.state.bookModal && <BookModal bookDetails={this.state.bookModal} onClose={() => {this.setState({bookModal: false})}} />}
            {!!this.state.subjectModal && <SubjectModal subject={this.state.subjectModal} bookModal={(time, subject) => {this.bookLesson(time, subject)}} user={this.props.user} onClose={() => {this.setState({subjectModal: false})}} />}
            <div className="row">
                <div className="profile-info col-lg-3 col-md-4">
                    <Avatar user={this.props.user} size={174} />
                        <div className="name">
                            {this.props.user.fullName}
                        </div>

                        <Rating rating={this.props.user.rating} />

                        {this.props.myUser.isAuth && <>
                            <button className="button btn-filled btn-full" onClick={() => {
                                this.props.history.push(`/chats/${this.props.user._id}`)
                            }}>Message</button>
                        </>}
                        {!this.props.myUser.isAuth && <>
                            <button className="button btn-filled btn-full" onClick={() => {
                                this.props.history.push('/login')
                            }}>Login for contact with {this.props.user.type === 'tutor' && <>tutor</>}{this.props.user.type === 'student' && <>student</>}</button>
                        </>}

                        <div className="specs">
                            <div className="row">
                                From<div className="col"></div><span>{this.props.user.country}</span>
                            </div>
                            <div className="row">
                                Native language<div className="col"></div><span>{this.props.user.native}</span>
                            </div>
                            {!!this.props.user.fluent && <div className="row">
                                Fluent language<div className="col"></div><span>{this.props.user.fluent}</span>
                            </div>}
                        </div>

                        {!!this.props.user.description && <>
                            <div style={{width: '100%', border: '1px solid rgba(0, 0, 0, 0.2)', margin: '10px 0'}} />

                            <div className="about-heading">About Me</div>
                        </>}

                        <div className="about-text">{this.props.user.description}</div>

                        <div style={{width: '100%', border: '1px solid rgba(0, 0, 0, 0.2)', margin: '10px 0'}} />

                        <div className="since-heading">Member since</div>

                        <div className="since-year">{new Date(this.props.user.createdAt).getFullYear()}</div>
                </div>
        
                <div className="col-lg-9 col-md-8">
                    {this.props.user.type === 'tutor' && <Subjects userId={this.props.user._id} onBook={subject => {
                        this.setState({subjectModal: subject})
                    }} />}
                    <Reviews userId={this.props.user._id} />
                </div>
            </div>

            {/* {this.props.user.type ==='tutor' && <div className="section col-lg-8 card col-md-12 card" ref={this.scheduleSection}>
                <div className="title">
                    Book a lesson
                </div>
                
                <Schedule 
                    bookLesson={(clickTime) => this.bookLesson(clickTime)}
                    withDate={true} 
                    schedule={this.props.user.schedule}
                    lessons={this.props.user.lessons}
                    time={this.props.user.time}
                    gmtDiff={Number(this.props.myUser.isAuth ? this.props.myUser.gmt : getTimezoneOffset()) - Number(this.props.user.gmt)} 
                />
            </div>} */}

            
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        myUser: state.user
    }
  }
  
export default connect(
    mapStateToProps
)(withRouter(OtherProfile))