import React from 'react'
import Footer from '../partials/Footer'
import Header from '../partials/Header'

class MainLayout extends React.Component {
    render() {
        return <>
            <Header />
            <div className="content">
                {this.props.children}
            </div>
            <Footer />
        </>
    }
}

export default MainLayout