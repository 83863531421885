import * as StoreConstants from '../types'
import chatsApi from '../../apis/chats'
import userApi from '../../apis/user'
import store from '../index'
import { randomInteger, scrollChatToBottom, setCustomTitle } from '../../controllers/FunctionsController'

export const getChats = (params) => (dispatch) => {
    return chatsApi.getChats(params).then(response => {
        if(response.success) {
            response.chats = response.chats.map(chat => ({
                ...chat, 
                messages: [],
                isGetted: false,
                isPreFetching: false,
                isTyping: false,
                user: [...chat.users.filter(user => user._id !== store.getState().user._id)][0]
            }))

            dispatch({
                type: StoreConstants.GET_CHATS,
                payload: response
            })
        }

        return response
    })
}

export const getMessages = (params) => (dispatch) => {
    return chatsApi.getMessages(params).then(response => {
        if(response.success) {
            response.chat.user = [...response.chat.users.filter(user => user._id !== store.getState().user._id)][0]
            response.chat.lastMessage = false
            response.chat.isGetted = true
            response.chat.isPreFetching = false
            response.chat.isTyping = false
            response.messages = response.messages.reverse()
            
            dispatch({
                type: StoreConstants.GET_MESSAGES,
                payload: {...response, userId: params.userId}
            })

            if(!params.lastMessageId) {
                setCustomTitle(`${response.chat.user.fullName} - ${process.env.REACT_APP_NAME}`)
                scrollChatToBottom()
            }
        }

        return response
    })
}

export const readMessages = (params) => (dispatch) => {
    let chat = store.getState().chats.chats.find(chat => chat.user._id === params.userId)

    if(!!chat) {
        dispatch({
            type: StoreConstants.SET_NO_READ_GLOBAL,
            payload: chat.noRead > 0 && store.getState().user.noRead > 0 ? store.getState().user.noRead - 1 : 0
        })
        dispatch({
            type: StoreConstants.SET_NO_READ,
            payload: {userId: params.userId, noRead: 0}
        })
        dispatch({
            type: StoreConstants.READ_MESSAGES,
            payload: {userId: params.userId, myId: store.getState().user._id}
        })        
    }

    chatsApi.readMessages(params).then(response => {
        if(response.success) {
            
        }
    })
}

export const sendMessage = (params) => (dispatch) => {
    let chat = store.getState().chats.chats.find(chat => chat.user._id === params.userId)

    if(!!chat) {
        dispatch({
            type: StoreConstants.SET_NO_READ_GLOBAL,
            payload: chat.noRead > 0 && store.getState().user.noRead > 0 ? store.getState().user.noRead - 1 : 0
        })
        dispatch({
            type: StoreConstants.SET_NO_READ,
            payload: {userId: params.userId, noRead: 0}
        })
    }
    
    let message = {
        _id: randomInteger(0, 99999999),
        user: store.getState().user,
        createdAt: Date.now(),
        type: 'message',
        text: params.text,
        isRead: false,
    }

    dispatch({
        type: StoreConstants.ADD_MESSAGE,
        payload: {message, userId: params.userId, myId: store.getState().user._id}
    })

    scrollChatToBottom()
    
    chatsApi.sendMessage(params)
}

export const updateOnline = (params) => (dispatch) => {
    userApi.getOnline(params).then(response => {
        if(response.success) {
            dispatch({
                type: StoreConstants.UPDATE_ONLINE,
                payload: {userId: params._id, online: response.online}
            })
        }
    })
}