import React from 'react'

import Home from '../pages/all/Home'
import Login from '../pages/auth/Login'
import Register from '../pages/auth/Register'
import Forgot from '../pages/auth/Forgot'
import Reset from '../pages/auth/Reset'
import Confirm from '../pages/user/Confirm'
import UploadCertificates from '../pages/user/tutor/UploadCertificates'
import SetSchedule from '../pages/user/tutor/SetSchedule'
import FindTutor from '../pages/all/FindTutor'
import Profile from '../pages/all/Profile'
import Chat from '../pages/user/Chat'
import Lessons from '../pages/user/Lessons'
import LeaveReview from '../pages/user/LeaveReview'
import Privacy from '../pages/all/Privacy'
import CreateProfile from '../pages/auth/Register/CreateProfile'
import CreateLesson from '../pages/user/tutor/CreateLesson'
import EditLesson from '../pages/user/tutor/EditLesson'
import EditProfile from '../pages/user/EditProfile'
import About from '../pages/all/About'
import Contact from '../pages/all/Contact'
import FAQ from '../pages/all/FAQ'

const routes = [
    {
        path: '/',
        exact: true,
        type: 'all',
        title: `Home - ${process.env.REACT_APP_NAME}`,
        component: () => <Home />
    },
    {
        path: '/login',
        exact: false,
        type: 'auth',
        title: `Login - ${process.env.REACT_APP_NAME}`,
        component: () => <Login />
    },
    {
        path: '/register',
        exact: false,
        type: 'auth',
        title: `Register - ${process.env.REACT_APP_NAME}`,
        component: () => <Register />
    },
    {
        path: '/forgot',
        exact: false,
        type: 'auth',
        title: `Forgot password? - ${process.env.REACT_APP_NAME}`,
        component: () => <Forgot />
    },
    {
        path: '/reset',
        exact: false,
        type: 'auth',
        title: `Reset password - ${process.env.REACT_APP_NAME}`,
        component: () => <Reset />
    },
    {
        path: '/confirm',
        exact: false,
        type: 'confirm',
        title: `Confirm email - ${process.env.REACT_APP_NAME}`,
        component: () => <Confirm />
    },
    {
        path: '/upload-certificates',
        exact: false,
        type: 'upload_certificates',
        title: `Send your certificate - ${process.env.REACT_APP_NAME}`,
        component: () => <UploadCertificates />
    },
    {
        path: '/create-lesson',
        exact: false,
        type: 'create_lesson',
        title: `Create lesson - ${process.env.REACT_APP_NAME}`,
        component: () => <CreateLesson />
    },
    {
        path: '/edit-lesson/:_id',
        exact: false,
        type: 'create_lesson',
        title: `Edit lesson - ${process.env.REACT_APP_NAME}`,
        component: () => <EditLesson />
    },
    {
        path: '/create-profile',
        exact: false,
        type: 'create_profile',
        title: `Create profile - ${process.env.REACT_APP_NAME}`,
        component: () => <CreateProfile />
    },
    {
        path: '/edit-profile',
        exact: false,
        type: 'user',
        title: `Edit profile - ${process.env.REACT_APP_NAME}`,
        component: () => <EditProfile />
    },
    {
        path: '/set-schedule',
        exact: false,
        type: 'schedule',
        title: `Set your schedule - ${process.env.REACT_APP_NAME}`,
        component: () => <SetSchedule />
    },
    {
        path: '/find-tutor',
        exact: false,
        type: 'all',
        title: `Find a tutor - ${process.env.REACT_APP_NAME}`,
        component: () => <FindTutor />
    },
    {
        path: '/profiles/:_id',
        exact: false,
        type: 'all',
        title: `Profile - ${process.env.REACT_APP_NAME}`,
        component: () => <Profile key={window.location.pathname} />
    },
    {
        path: '/chats',
        exact: false,
        type: 'user',
        title: `Chats - ${process.env.REACT_APP_NAME}`,
        component: () => <Chat />
    },
    {
        path: '/my-lessons',
        exact: false,
        type: 'user',
        title: `My lessons - ${process.env.REACT_APP_NAME}`,
        component: () => <Lessons />
    },
    {
        path: '/leave-review',
        exact: false,
        type: 'user',
        title: `Leave a review - ${process.env.REACT_APP_NAME}`,
        component: () => <LeaveReview />
    },
    {
        path: '/privacy',
        exact: false,
        type: 'all',
        title: `Terms & Conditions - ${process.env.REACT_APP_NAME}`,
        component: () => <Privacy />
    },
    {
        path: '/about',
        exact: false,
        type: 'all',
        title: `About - ${process.env.REACT_APP_NAME}`,
        component: () => <About />
    },
    {
        path: '/contact',
        exact: false,
        type: 'all',
        title: `Contact - ${process.env.REACT_APP_NAME}`,
        component: () => <Contact />
    },
    {
        path: '/faq',
        exact: false,
        type: 'all',
        title: `FAQ - ${process.env.REACT_APP_NAME}`,
        component: () => <FAQ />
    },
]

export default routes