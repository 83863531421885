import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from "react-router-dom"
import { bindActionCreators } from 'redux'
import Dialog from '../../components/chat/Dialog'
import DialogItem from '../../components/chat/DialogItem'
import * as chatsActions from '../../store/actions/chats'
import ForumIcon from '@material-ui/icons/Forum';
import MessageIcon from '@material-ui/icons/Message';
import SimpleBar from 'simplebar-react';

let waitPreFetching = false
let canPreFethcing = true

class Chat extends React.Component {
    state = {
        perPage: 20
    }

    componentDidMount() {
        if(!this.props.chats.isGetted) {
            this.props.chatsActions.getChats({perPage: this.state.perPage}).then((response) => {
                if(response.success)
                    canPreFethcing = this.state.perPage === response.chats.length
            })
        }
    }

    onScroll = (e) => {
        if(waitPreFetching || !canPreFethcing)
            return

        if((e.nativeEvent.target.scrollHeight - (e.nativeEvent.target.scrollTop + e.nativeEvent.target.offsetHeight)) <= 100) {
            waitPreFetching = true

            this.props.chatsActions.getChats({
                perPage: this.state.perPage,
                lastChatId: this.props.chats.chats[this.props.chats.chats.length-1]._id
            }).then((response) => {
                if(response.success)
                    canPreFethcing = this.state.perPage === response.chats.length

                waitPreFetching = false
            })
        }
    }

    render() {
        return <div className="chat-page container">
            <div className="row">
                <div className="col-md-6 col-lg-4 col-xl-3 chats-wrapper">
                    <SimpleBar className="chat-list" onScrollCapture={(e) => this.onScroll(e)}>
                        {this.props.chats.isFetching && <div className="d-flex justify-content-center loader">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>}

                        {!this.props.chats.isFetching && this.props.chats.chats.filter(chat => !!chat.lastMessage).length === 0 && <div className="empty-data">
                            <ForumIcon /><br/>
                            You have no chats yet.
                        </div>}

                        {!this.props.chats.isFetching && <>
                            {this.props.chats.chats.filter(chat => !!chat.lastMessage).map(chat => <DialogItem key={chat._id} chat={chat} />)}
                        </>}
                    </SimpleBar>
                </div>

                <div className={`col-md-6 col-lg-8 col-xl-9 dialog-wrapper ${window.location.pathname === '/chats' ? 'm-hide' : ''}`}>
                    <div className="chat-block">
                        {this.props.chats.isGetted && <Switch>
                            <Route path={`/chats/:_id`}>
                                <Dialog key={window.location.pathname} />
                            </Route>
                            <Route component={() =>
                                <div className="empty-data">
                                    <MessageIcon /><br/>
                                    Select chat.
                                </div>
                            } />
                        </Switch>}
                        
                        {this.props.chats.isFetching && <div className="d-flex justify-content-center loader">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        chats: state.chats
    }
}

function mapDispatchToProps(dispatch) {
    return {
        chatsActions: bindActionCreators(chatsActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Chat))