import React from 'react'
import countries from "countries-list";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Avatar from '../profile/Avatar';
import Rating from './Rating';
import { timeAt } from '../../controllers/DateController';

class ReviewCard extends React.Component {
    render() {
        return <div className="col-12 review-card">
            <div className="user">
                <Avatar user={this.props.review.from} size={50} />
                <div className="name" onClick={() => {
                    this.props.history.push(`/profiles/${this.props.review.from._id}`)
                }}>
                    {this.props.review.from.fullName}
                    <span><Rating rating={{average: this.props.review.stars}} /> {timeAt(this.props.review.createdAt)}</span>
                </div>
            </div>
            {!!this.props.review.text && <div className="text">
                {this.props.review.text}
            </div>}
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
  }
  
export default connect(
    mapStateToProps
)(withRouter(ReviewCard))