import React from 'react'
import userApi from '../../../apis/user'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom"
import SelectSchedule from '../../../components/tutor/SelectSchedule'

class setSchedule extends React.Component {
    state = {
        code: '',
        errors: [],
        isFetching: false,
        schedule: [],
    }

    submit = (e) => {
        e.preventDefault();

        if(this.state.isFetching)
            return

        this.setState({isFetching: true})
        
        userApi.setConfLessons().then(response => {
            if(response.success) {
                window.location.href = `/profiles/${this.props.user._id}`
            } else {
                this.setState({errors: response.errors})
            }

            this.setState({isFetching: false})
        })
    }

    render() {
        return <div className="schedule-page col-md-6" style={{margin: '0 auto'}}>
            <div className="auth-card " style={{width: 'unset'}}>
                {this.state.isFetching && <div className="d-flex justify-content-center loader">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>}

                <h2 style={{fontWeight: '600'}} className="mb-4">SCHEDULE</h2>
                
                <form onSubmit={this.submit} className="row" style={{flexDirection: 'row'}}>
                    <div className="mb-3">
                        {/* <Schedule onChange={(schedule) => {
                            this.setState({schedule})
                        }} /> */}
                        <SelectSchedule />
                        {this.state.errors.filter((error) => error.param === 'code').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}
                    </div>

                    {this.state.errors.filter((error) => error.param === 'all').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}

                    <button style={{margin: '0 auto'}} className="button btn-filled col-md-12" type="submit">Save</button>
                </form>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(
    mapStateToProps
)(withRouter(setSchedule))