import React from 'react'
import tutorApi from '../../../apis/tutor'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom"
import categoryApi from '../../../apis/category'
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import SimpleSelect from '../../../components/inputs/SimpleSelect'
import countries from 'countries-list'
import Avatar from '../../../components/subject/Avatar';
import { setCustomTitle } from '../../../controllers/FunctionsController'

const languagesCodes = Object.keys(countries.languages);
const languagesNames = languagesCodes.map(code => countries.languages[code].name);

class EditLesson extends React.Component {
    state = {
        errors: [],
        category: '',
        language: '',
        keys: [],
        key: '',
        title: '',
        thumbnail: {path: ''},
        isFetching: true,
        price: 1,
        time: 60,
        trial: false
    }

    componentDidMount() {
        tutorApi.getLesson({_id: this.props.match.params._id}).then(response => {
            if(response.success) {
                this.setState({
                    category: response.subject.category,
                    language: response.subject.language,
                    keys: response.subject.keys,
                    title: response.subject.title,
                    thumbnail: {path: response.subject.thumbnail},
                    price: response.subject.price,
                    time: response.subject.time,
                    trial: response.subject.trial
                })
            } else {
                this.setState({errors: response.errors})
            }

            this.setState({isFetching: false})
        })

        setCustomTitle(`Edit a lesson - ${process.env.REACT_APP_NAME}`)
    }

    submit = (e, i) => {
        e.preventDefault();

        if(this.state.isFetching)
            return

        this.setState({isFetching: true})

        let formData = new FormData() 
        
        formData.append('_id', this.props.match.params._id)
        formData.append('category', this.state.category)
        formData.append('keys', JSON.stringify(this.state.keys))
        formData.append('title', this.state.title)

        if(!!this.state.thumbnail.path)
            formData.append('thumbnail', this.state.thumbnail.file)

        formData.append('price', this.state.price)
        formData.append('time', this.state.time)
        formData.append('language', this.state.language)
        formData.append('trial', this.state.trial)
        
        tutorApi.editLesson(formData).then(response => {
            if(response.success) {
                this.props.history.push(`/profiles/${this.props.user._id}`)
            } else {
                this.setState({errors: response.errors})
            }

            this.setState({isFetching: false})
        })
    }

    render() {
        return <div className="auth-block">
            <div className="auth-card" style={{width: 588}}>
                {this.state.isFetching && <div className="d-flex justify-content-center loader">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>}

                <h2>EDIT A LESSON</h2>
                
                <form onSubmit={this.submit}>
                    <div className="mb-3">
                        <label className="form-label">LESSON *</label>
                        <input type="text" value={this.state.title} placeholder="Write your lesson title" onChange={(e) => {
                            this.setState({title: e.target.value})
                        }} className="input-simple" />
                        {this.state.errors.filter((error) => error.param === 'title').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}
                    </div>
                    <div className="mb-3 mt-5 key-take">
                        <span></span>
                        <label className="form-label">KEY TAKE AWAYS *</label>
                        <input type="text" value={this.state.key} placeholder="Add key points" onKeyPress={e => {
                            if (e.which == '13') {
                                e.preventDefault();
                                if(!!this.state.key) {
                                    this.setState({keys: [...this.state.keys, this.state.key], key: ''})
                                }
                            }
                        }} onChange={(e) => {
                            e.preventDefault()
                            this.setState({key: e.target.value})
                        }} className="input-simple" />
                        <button className="add" onClick={e => {
                            e.preventDefault()
                            if(!!this.state.key) {
                                this.setState({keys: [...this.state.keys, this.state.key], key: ''})
                            }
                        }}><AddIcon /></button>
                        {this.state.errors.filter((error) => error.param === 'keys').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}

                        {this.state.keys.map((key, index) => <div className="key-take-away" key={key}>
                            <span />
                            <p>{key}</p>
                            <div className="col" /> 
                            <button
                                onClick={e => {
                                    e.preventDefault()
                                    this.setState({keys: this.state.keys.filter((key, i) => index !== i)})
                                }}
                                className="button btn-cancel" 
                                style={{margin: 0, width: 76, minWidth: 76, height: 30, padding: '6px 0', marginLeft: 20}}
                            >Delete</button>
                        </div>)}
                    </div>
                    <div className="mb-3 mt-5">
                        <label className="form-label">WHAT LANGUAGE IS THE LESSON IN *</label>
                        <SimpleSelect 
                            value={this.state.language}
                            values={[
                                {label: 'English', value: 'English'},
                                ...languagesNames.map(language => ({value: language, label: language})).filter(x => x.label !== 'English')
                            ]}
                            onClick={(value) => {
                                this.setState({language: value})
                            }}
                        />
                        {this.state.errors.filter((error) => error.param === 'language').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}
                    </div>
                    <div className="mb-3 mt-5">
                        <label className="form-label">CATEGORY *</label>
                        <SimpleSelect 
                            value={this.state.category}
                            values={[
                                ...this.props.user.categories.map(category => ({value: category._id, label: category.name}))
                            ]} 
                            onClick={(value) => {
                                this.setState({category: value})
                            }}
                        />
                        {this.state.errors.filter((error) => error.param === 'category').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}
                    </div>
                    
                    <label className="form-label mt-5">THUMBNAIL</label>
                    <div className="mb-3" style={{flexDirection: 'row', alignItems: 'center', display: 'flex'}}>
                        <Avatar avatar={this.state.thumbnail.path} size={160} />
                        <div>
                            <label style={{fontSize: 16}} className="button btn-empty" style={{marginLeft: 36}}>
                                Upload
                                <input 
                                    type="file"
                                    onChange={(e) => {
                                        let thumbnail = {
                                            path: (window.URL || window.webkitURL).createObjectURL(new Blob([e.target.files[0]], {type: e.target.files[0].type})), 
                                            file: e.target.files[0], 
                                            name: e.target.files[0].name, 
                                            type: e.target.files[0].name.split('.').pop(),
                                            size: e.target.files[0].size / 1000
                                        }

                                        this.setState({thumbnail})
                                    }}
                                    style={{display: 'none'}} 
                                    accept="image/jpeg,image/png,image/jpg" 
                                />
                            </label>
                            <button className="button btn-cancel" onClick={(e) => {
                                e.preventDefault()
                                this.setState({thumbnail: {path: ''}})
                            }}>Remove</button>
                            <label className="form-label" style={{margin: '12px 0 0 36px', fontSize: 16, textTransform: 'uppercase'}}>Please make sure its clear</label>
                        </div>
                        {this.state.errors.filter((error) => error.param === 'thumbnail').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}
                    </div>
                    <div className="mb-3 mt-5">
                        <label className="form-label">HOW MUCH TIME *</label>
                        <SimpleSelect 
                            value={this.state.time}
                            values={[
                                {value: 15, label: '15 minutes'},
                                {value: 30, label: '30 minutes'},
                                {value: 45, label: '45 minutes'},
                                {value: 60, label: '60 minutes'},
                                {value: 75, label: '75 minutes'},
                                {value: 90, label: '90 minutes'},
                                {value: 105, label: '105 minutes'},
                                {value: 120, label: '120 minutes'},
                            ]} 
                            onClick={(value) => {
                                this.setState({time: value})
                            }}
                        />
                        {this.state.errors.filter((error) => error.param === 'language').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}
                    </div>
                    <div className="mb-3 mt-5">
                        <label className="form-label">TRIAL LESSON *</label>
                        <SimpleSelect 
                            value={this.state.trial}
                            values={[
                                {value: false, label: 'No'},
                                {value: true, label: 'Yes'},
                            ]} 
                            onClick={(value) => {
                                this.setState({trial: value})
                            }}
                        />
                        {this.state.errors.filter((error) => error.param === 'language').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}
                    </div>
                    <div className="mb-3 mt-5" style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                        <label className="form-label">PRICE FOR LESSON £ *</label>
                        <div className="col" />
                        <input type="number" min="1" style={{width: 'unset'}} onChange={(e) => {
                            this.setState({price: e.target.value})
                        }} className="input-simple" value={this.state.price} />
                        {this.state.errors.filter((error) => error.param === 'title').map((error, index) => <span key={index} className="error-msg col-12">{error.msg}</span>)}
                    </div>

                    <p>TheTutor.Link will charge a 5% admin fee from this total.</p>
                
                    {this.state.errors.filter((error) => error.param === 'all').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}

                    <button className="button btn-filled" style={{margin: '25px auto', display: 'block'}} type="submit">Save</button>
                </form>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(
    mapStateToProps
)(withRouter(EditLesson))