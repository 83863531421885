import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from "react-router-dom"
import { bindActionCreators } from 'redux'
import * as chatsActions from '../../store/actions/chats'
import Avatar from '../profile/Avatar'
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ErrorIcon from '@material-ui/icons/Error';
import DialogInput from './DialogInput'
import Message from './Message'
import { scrollChatToBottom, setCustomTitle } from '../../controllers/FunctionsController'
import { onlineAt } from '../../controllers/DateController'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SimpleBar from 'simplebar-react';

let waitRead = false
let waitPreFetching = false
let canPreFethcing = true

class Dialog extends React.Component {
    state = {
        userId: this.props.match.params._id,
        perPage: 50,
        error: false,
        errors: []
    }

    focusPage = () => {
        let chat = document.getElementById("chat-messages");
	
        if(!!chat) {
            if(chat.scrollTop === (chat.scrollHeight - chat.offsetHeight)) {
                this.readMessages()
            }
        }
    }

    readMessages = () => {
        if(waitRead)
            return

        waitRead = true

        setTimeout(() => {
            waitRead = false
        }, 100)

        let chat = this.props.chats.chats.find(chat => chat.user._id === this.state.userId)
        if(!!chat && !!chat.messages.find(message => !message.isRead && message.user._id !== this.props.user._id)) {
            this.props.chatsActions.readMessages({userId: this.state.userId})
        }
    }

    componentDidMount() {
        window.addEventListener('mousemove', this.focusPage);

        this.readMessages()

        let chat = this.props.chats.chats.find(chat => chat.user._id === this.state.userId)

        if(!chat) {
            this.props.chatsActions.getMessages({perPage: this.state.perPage, userId: this.state.userId}).then((response) => {
                if(!response.success)
                    this.setState({error: true, errors: response.errors})
                else {
                    canPreFethcing = this.state.perPage === response.messages.length
                    // setCustomTitle(`${response.user.fullName} - ${process.env.REACT_APP_NAME}`)
                }
            })
        } else {
            this.props.chatsActions.updateOnline({_id: chat.user._id})

            if(!chat.isGetted) {
                this.props.chatsActions.getMessages({perPage: this.state.perPage, userId: this.state.userId}).then((response) => {
                    if(response.success)
                        canPreFethcing = this.state.perPage === response.messages.length    
                })
            } else {
                scrollChatToBottom()
            }
            setTimeout(() => {
                setCustomTitle(`${chat.user.fullName} - ${process.env.REACT_APP_NAME}`)
            }, 100);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('mousemove', this.focusPage);
    }

    onScroll = (e) => {
        console.log(e)
        if(waitPreFetching || !canPreFethcing)
            return

        if(e.nativeEvent.target.scrollTop <= 100) {
            waitPreFetching = true

            let chat = this.props.chats.chats.find(chat => chat.user._id === this.state.userId)
            this.props.chatsActions.getMessages({
                perPage: this.state.perPage, 
                userId: this.state.userId, 
                lastMessageId: chat.messages[0]._id
            }).then((response) => {
                if(response.success)
                    canPreFethcing = this.state.perPage === response.messages.length

                waitPreFetching = false
            })
        }
    }

    render() {
        if(this.state.error)
            return <div className="chat-dialog">
                <div className="empty-data">
                    <ErrorIcon /><br/>
                    {this.state.errors[0].msg}
                </div>
            </div>

        let chat = this.props.chats.chats.find(chat => chat.user._id === this.state.userId)

        return <div className="chat-dialog">
            {!chat && <div className="d-flex justify-content-center loader">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>}

            {!!chat && <>
                <div className="chat-user" onClick={() => {
                    this.props.history.push(`/profiles/${chat.user._id}`)
                }}>
                    <Link to="/chats" className="button btn-back-mobile" onClick={(e) => {
                        e.stopPropagation()
                    }}><ArrowBackIcon /></Link>
                    <Avatar user={chat.user} size={60} />
                    <div className="user-info">
                        <div className="name">{chat.user.fullName}</div>
                        {!chat.isTyping && <div className={`online-status ${chat.user.isOnline ? 'active' : ''}`}>{chat.user.isOnline ? 'online' : 'was last seen ' + onlineAt(chat.user.onlineAt)}</div>}
                        {chat.isTyping && <div className="online-status typing">Typing</div>}
                    </div>
                </div>

                <SimpleBar  onScrollCapture={(e) => this.onScroll(e)} className="chat-messages col" id="chat-messages">
                    {chat.messages.length === 0 && chat.isGetted && <div className="empty-data">
                        <ChatBubbleIcon /><br/>
                        You have no messages with this user.<br/>
                        Be the first to write something!
                    </div>}
                    {chat.isGetted && chat.messages.map((message, i) => {
                        return <Message message={message} prevMessage={!!chat.messages[i-1] ? chat.messages[i-1] : false} key={message._id} />
                    })}
                    {!chat.isGetted && <div className="d-flex justify-content-center loader">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>}
                </SimpleBar>

                <DialogInput focusPage={() => {this.focusPage()}} userId={this.state.userId} />
            </>}
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        chats: state.chats
    }
}

function mapDispatchToProps(dispatch) {
    return {
        chatsActions: bindActionCreators(chatsActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Dialog))