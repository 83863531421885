import React from 'react'
import { connect } from 'react-redux'
import { getTimezoneOffset } from '../../controllers/DateController'
import Schedule from '../tutor/Schedule'

class SubjectModal extends React.Component {
    render() {
        return <div className="book-modal modal" onClick={() => {
            this.props.onClose()
        }}>
            <div className="modal-dialog" onClick={(e) => {
                e.stopPropagation()
            }}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Select a time</h5>
                    </div>
                    <div className="modal-body">
                    <Schedule 
                        bookLesson={(clickTime) => this.props.bookModal(clickTime, this.props.subject)}
                        withDate={true} 
                        schedule={this.props.user.schedule}
                        lessons={this.props.user.lessons}
                        time={this.props.user.time}
                        gmtDiff={Number(this.props.myUser.isAuth ? this.props.myUser.gmt : getTimezoneOffset()) - Number(this.props.user.gmt)} 
                    />
                    </div>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        myUser: state.user
    }
  }
  
export default connect(
    mapStateToProps
)(SubjectModal)