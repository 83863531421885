import React from 'react'
import { withRouter } from "react-router-dom"
import { days, times } from '../../controllers/DateController'
import '../../assets/styles/schedule.css'
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import userApi from '../../apis/user'
import { connect } from 'react-redux';
import SimpleBar from 'simplebar-react';

class SelectSchedule extends React.Component {
    state = {
        // week: getWeek(new Date()),
        currentDate: new Date(),
        scheduleChanges: [],
        scheduleChange: false,
        schedule: this.props.user.schedule
    }

    save = () => {
        let newSchedule = this.state.schedule

        for (let i = 0; i < this.state.scheduleChanges.length; i++) {
            if(this.state.scheduleChanges[i].status === 'create') {
                let addStart = newSchedule.find(sch => sch.day === this.state.scheduleChanges[i].day && sch.start-900 === this.state.scheduleChanges[i].end)
                let addEnd = newSchedule.find(sch => sch.day === this.state.scheduleChanges[i].day && sch.end+900 === this.state.scheduleChanges[i].start)

                newSchedule = newSchedule.filter(sch => !(sch.day === this.state.scheduleChanges[i].day && sch.start >= this.state.scheduleChanges[i].start && sch.end <= this.state.scheduleChanges[i].end))

                if(addStart && addEnd) {
                    newSchedule = newSchedule.map(
                        sch => (sch.start === addEnd.start && sch.day === addEnd.day && sch.end === addEnd.end) ? 
                        {...sch, end: addStart.end} : sch
                    )

                    newSchedule = newSchedule.filter(
                        sch => !(sch.start === addStart.start && sch.end === addStart.end && sch.day === addStart.day)
                    )
                    continue
                }

                if(addStart) {
                    newSchedule = newSchedule.map(
                        sch => (sch.start === addStart.start && sch.day === addStart.day && sch.end === addStart.end) ? 
                        {...sch, start: this.state.scheduleChanges[i].start} : sch
                    )
                    continue
                }

                
                if(addEnd) {
                    newSchedule = newSchedule.map(
                        sch => (sch.start === addEnd.start && sch.day === addEnd.day && sch.end === addEnd.end) ? 
                        {...sch, end: this.state.scheduleChanges[i].end} : sch
                    )
                    continue
                }

                newSchedule.push(this.state.scheduleChanges[i])
            }

            if(this.state.scheduleChanges[i].status === 'delete') {
                let sliceFull = newSchedule.find(sch => sch.start === this.state.scheduleChanges[i].start && sch.end === this.state.scheduleChanges[i].end && sch.day === this.state.scheduleChanges[i].day)
                
                if(sliceFull) {
                    newSchedule = newSchedule.filter(
                        sch => !(sch.start === this.state.scheduleChanges[i].start && sch.end === this.state.scheduleChanges[i].end && sch.day === this.state.scheduleChanges[i].day)
                    )
                    continue;
                }

                let sliceStart = newSchedule.find(sch => sch.start === this.state.scheduleChanges[i].start && sch.day === this.state.scheduleChanges[i].day)
                
                if(sliceStart) {
                    newSchedule = newSchedule.map(
                        sch => (sch.start === this.state.scheduleChanges[i].start && sch.day === this.state.scheduleChanges[i].day) ? 
                        {...sch, start: this.state.scheduleChanges[i].end+900} : sch
                    )
                    continue;
                }

                let sliceEnd = newSchedule.find(sch => sch.end === this.state.scheduleChanges[i].end && sch.day === this.state.scheduleChanges[i].day)
                
                if(sliceEnd) {
                    newSchedule = newSchedule.map(
                        sch => (sch.end === this.state.scheduleChanges[i].end && sch.day === this.state.scheduleChanges[i].day) ? 
                        {...sch, end: this.state.scheduleChanges[i].start-900} : sch
                    )
                    continue;
                }

                let sliceMiddle = newSchedule.find(sch => sch.start < this.state.scheduleChanges[i].start && sch.end > this.state.scheduleChanges[i].end && sch.day === this.state.scheduleChanges[i].day)
                
                if(sliceMiddle) {
                    let addSch = {...sliceMiddle, start: this.state.scheduleChanges[i].end+900}

                    newSchedule = [...newSchedule.map(
                        sch => (sch.start === sliceMiddle.start && sch.end === sliceMiddle.end && sch.day === this.state.scheduleChanges[i].day) ? 
                        {...sch, end: this.state.scheduleChanges[i].start-900} : sch
                    ), addSch]
                    continue;
                }
            }
        }

        this.setState({
            scheduleChange: false,
            scheduleChanges: [],
            schedule: newSchedule
        })

        userApi.setSchedule({
            schedule: newSchedule,
        }).then(response => {
            
        })
    }

    setScheduleChange = (time, day, active) => {
        let existChange = this.state.scheduleChanges.find(sch => sch.start <= time && sch.end >= time && sch.day === day)

        if(!!existChange) {
            this.setState({
                scheduleChanges: this.state.scheduleChanges.filter(sch => sch.start !== existChange.start && sch.end !== existChange.end && sch.day !== existChange.day)
            }, () => {
                this.setScheduleChange(time, day, active)
            })
            return
        }

        let otherSlice = false

        if(this.state.scheduleChange) {
            let currentSlice = this.state.schedule.find(sch => sch.start <= time && sch.end >= time && sch.day === day)
            let checkSlice = this.state.schedule.find(sch => sch.start <= this.state.scheduleChange.first && sch.end >= this.state.scheduleChange.first && sch.day === this.state.scheduleChange.day)
            
            if(currentSlice && checkSlice && (currentSlice.start !== checkSlice.start || currentSlice.end !== checkSlice.end)) {
                otherSlice = true
            }
        }

        if(!this.state.scheduleChange || otherSlice || (this.state.scheduleChange && (this.state.scheduleChange.day !== day || this.state.scheduleChange.status !== (active ? 'delete' : 'create'))))
            this.setState({
                scheduleChange: {
                    day,
                    first: time,
                    second: time,
                    status: active ? 'delete' : 'create'
                }
            })
        else {
            let first = this.state.scheduleChange.first > this.state.scheduleChange.second ? this.state.scheduleChange.second : this.state.scheduleChange.first
            let second = this.state.scheduleChange.first <= this.state.scheduleChange.second ? this.state.scheduleChange.second : this.state.scheduleChange.first

            let scheduleChange = {
                day: this.state.scheduleChange.day,
                start: first,
                end: second,
                status: this.state.scheduleChange.status
            }

            this.setState({
                scheduleChanges: [...this.state.scheduleChanges, scheduleChange].filter(sch => sch.day !== scheduleChange.day || !(sch.start !== scheduleChange.start && sch.end !== scheduleChange.end && sch.start > scheduleChange.start && sch.end < scheduleChange.end)),
                scheduleChange: false
            })
        }
    }

    seeScheduleChange = (time, day, active) => {
        let otherSlice = false

        if(this.state.scheduleChange) {
            let currentSlice = this.state.schedule.find(sch => sch.start <= time && sch.end >= time && sch.day === day)
            let checkSlice = this.state.schedule.find(sch => sch.start <= this.state.scheduleChange.first && sch.end >= this.state.scheduleChange.first && sch.day === this.state.scheduleChange.day)
            
            if(currentSlice && checkSlice && (currentSlice.start !== checkSlice.start || currentSlice.end !== checkSlice.end)) {
                otherSlice = true
            }
        }

        if(this.state.scheduleChange && !otherSlice && this.state.scheduleChange.day === day && this.state.scheduleChange.status === (active ? 'delete' : 'create')) {
            this.setState({
                scheduleChange: {
                    day,
                    first: this.state.scheduleChange.first,
                    second: time,
                    status: active ? 'delete' : 'create'
                }
            })


        }

        if((this.state.scheduleChange && (this.state.scheduleChange.day !== day || this.state.scheduleChange.status !== (active ? 'delete' : 'create'))) || otherSlice) {
            this.setState({
                scheduleChange: {
                    day: this.state.scheduleChange.day,
                    first: this.state.scheduleChange.first,
                    second: this.state.scheduleChange.first,
                    status: this.state.scheduleChange.status
                }
            })
        }
    }

    render() {
        return <SimpleBar className="select-schedule">
            <div className="head">
                <div style={{minWidth: 60}}></div>
                {days.map(day => {
                    return <div className="day-name">{day}</div>
                })}
            </div>

            <SimpleBar className="body">
                <div className="time">
                    {times.map(time => {
                        return <div className="time-name">{time}</div>
                    })}
                </div>

                {days.map((day, i) => {
                    return <div className="day">
                        {times.map(time => {
                            let schedulesStart = this.state.schedule.filter(sch => sch.day === i+1)
                            let schedulesChangedStart = this.state.scheduleChanges.filter(sch => sch.day === i+1)

                            let time1 = Number(time.slice(0, 2))*60*60
                            let time1active = false
                            let time2 = Number(time.slice(0, 2))*60*60+15*60
                            let time2active = false
                            let time3 = Number(time.slice(0, 2))*60*60+30*60
                            let time3active = false
                            let time4 = Number(time.slice(0, 2))*60*60+45*60                 
                            let time4active = false         

                            for (let i = 0; i < schedulesStart.length; i++) {
                                if(!time1active)
                                    time1active = schedulesStart[i] && time1 >= schedulesStart[i].start && time1 <= schedulesStart[i].end

                                if(!time2active)
                                    time2active = schedulesStart[i] && time2 >= schedulesStart[i].start && time2 <= schedulesStart[i].end
                                
                                if(!time3active)
                                    time3active = schedulesStart[i] && time3 >= schedulesStart[i].start && time3 <= schedulesStart[i].end    
                                
                                if(!time4active)
                                    time4active = schedulesStart[i] && time4 >= schedulesStart[i].start && time4 <= schedulesStart[i].end 
                            }

                            let time1selected = false
                            let time2selected = false
                            let time3selected = false                
                            let time4selected = false 

                            if(this.state.scheduleChange && this.state.scheduleChange.day === i+1) {
                                let first = this.state.scheduleChange.first > this.state.scheduleChange.second ? this.state.scheduleChange.second : this.state.scheduleChange.first
                                let second = this.state.scheduleChange.first <= this.state.scheduleChange.second ? this.state.scheduleChange.second : this.state.scheduleChange.first
                                
                                if(!time1selected)
                                    time1selected = time1 >= first && time1 <= second

                                if(!time2selected)
                                    time2selected = time2 >= first && time2 <= second
                                
                                if(!time3selected)
                                    time3selected = time3 >= first && time3 <= second
                                
                                if(!time4selected)
                                    time4selected = time4 >= first && time4 <= second
                            }

                            let time1changed = false
                            let time2changed = false
                            let time3changed = false                
                            let time4changed = false 
                            let changeStatus = false
                            let lastChangeTime = 0

                            for (let i = 0; i < schedulesChangedStart.length; i++) {
                                if(!time1changed)
                                    time1changed = schedulesChangedStart[i] && time1 >= schedulesChangedStart[i].start && time1 <= schedulesChangedStart[i].end

                                if(!time2changed)
                                    time2changed = schedulesChangedStart[i] && time2 >= schedulesChangedStart[i].start && time2 <= schedulesChangedStart[i].end
                                
                                if(!time3changed)
                                    time3changed = schedulesChangedStart[i] && time3 >= schedulesChangedStart[i].start && time3 <= schedulesChangedStart[i].end    
                                
                                if(!time4changed)
                                    time4changed = schedulesChangedStart[i] && time4 >= schedulesChangedStart[i].start && time4 <= schedulesChangedStart[i].end 
                                
                                if(!!time1changed || !!time2changed || !!time3changed || !!time4changed) {
                                    changeStatus = schedulesChangedStart[i].status
                                    lastChangeTime = schedulesChangedStart[i].end
                                    break;
                                }
                            }

                            return <div className="time-hour">
                                <div 
                                    onClick={() => {this.setScheduleChange(time1, i+1, time1active)}}
                                    onMouseEnter={() => {this.seeScheduleChange(time1, i+1, time1active)}}
                                    className={`time-hour-part 
                                        ${time1active ? 'active' : ''} 
                                        ${time1selected ? 'selected' : ''} 
                                        ${time1changed ? 'changed' : ''}
                                        ${(time1selected && this.state.scheduleChange.status === 'create') || (time1changed && changeStatus === 'create') ? 'create' :''} 
                                        ${(time1selected && this.state.scheduleChange.status === 'delete') || (time1changed && changeStatus === 'delete') ? 'delete' :''} 

                                    `}
                                >
                                    {lastChangeTime === time1 && <>
                                        {changeStatus === 'create' && <AddIcon />}
                                        {changeStatus === 'delete' && <RemoveIcon />}
                                    </>}
                                </div>
                                <div 
                                    onClick={() => {this.setScheduleChange(time2, i+1, time2active)}} 
                                    onMouseEnter={() => {this.seeScheduleChange(time2, i+1, time2active)}}
                                    className={`time-hour-part 
                                    ${time2active ? 'active' : ''} 
                                    ${time2selected ? 'selected' : ''} 
                                    ${time2changed ? 'changed' : ''}
                                    ${(time2selected && this.state.scheduleChange.status === 'create') || (time2changed && changeStatus === 'create') ? 'create' :''} 
                                    ${(time2selected && this.state.scheduleChange.status === 'delete') || (time2changed && changeStatus === 'delete') ? 'delete' :''} 
                                    `}
                                    >
                                    {lastChangeTime === time2 && <>
                                        {changeStatus === 'create' && <AddIcon />}
                                        {changeStatus === 'delete' && <RemoveIcon />}
                                    </>}
                                </div>
                                <div 
                                    onClick={() => {this.setScheduleChange(time3, i+1, time3active)}} 
                                    onMouseEnter={() => {this.seeScheduleChange(time3, i+1, time3active)}}
                                    className={`time-hour-part 
                                    ${time3active ? 'active' : ''} 
                                    ${time3selected ? 'selected' : ''} 
                                    ${time3changed ? 'changed' : ''}
                                    ${(time3selected && this.state.scheduleChange.status === 'create') || (time3changed && changeStatus === 'create') ? 'create' :''} 
                                    ${(time3selected && this.state.scheduleChange.status === 'delete') || (time3changed && changeStatus === 'delete') ? 'delete' :''} 
                                    `}
                                    >
                                    {lastChangeTime === time3 && <>
                                        {changeStatus === 'create' && <AddIcon />}
                                        {changeStatus === 'delete' && <RemoveIcon />}
                                    </>}
                                </div>
                                <div 
                                    onClick={() => {this.setScheduleChange(time4, i+1, time4active)}} 
                                    onMouseEnter={() => {this.seeScheduleChange(time4, i+1, time4active)}}
                                    className={`time-hour-part 
                                    ${time4active ? 'active' : ''} 
                                    ${time4selected ? 'selected' : ''} 
                                    ${time4changed ? 'changed' : ''}
                                    ${(time4selected && this.state.scheduleChange.status === 'create') || (time4changed && changeStatus === 'create') ? 'create' :''} 
                                    ${(time4selected && this.state.scheduleChange.status === 'delete') || (time4changed && changeStatus === 'delete') ? 'delete' :''} 
                                    `}
                                    >
                                    {lastChangeTime === time4 && <>
                                        {changeStatus === 'create' && <AddIcon />}
                                        {changeStatus === 'delete' && <RemoveIcon />}
                                    </>}
                                </div>
                            </div>
                        })}
                    </div>
                })}
            </SimpleBar>

            <div className="actions">
                <p style={{margin: 0}}>Times are shown in your local timezone</p>
                <div className="col" />
                {!!this.state.scheduleChanges.length && <button className="button btn-empty" onClick={(e) => {
                    e.preventDefault()
                    this.save()
                }}>Save</button>}
                {(!!this.state.scheduleChanges.length || !!this.state.scheduleChange) && <button className="button btn-cancel" onClick={() => {
                    this.setState({
                        scheduleChange: false,
                        scheduleChanges: []
                    })
                }}>Cancel</button>}
            </div>
        </SimpleBar>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
  }
  
export default connect(
    mapStateToProps
)(withRouter(SelectSchedule))