import { handleError, handleSuccess } from "."
import CookieController from "../controllers/CookieController"
import SocketController from "../controllers/SocketController"

let apiUrl = `${process.env.REACT_APP_API_URL}/api/chats/`

const apis = {
    getChats: function(params) {
        let searchParameters = new URLSearchParams()
            
        Object.keys(params).forEach(function(parameterName) {
            searchParameters.append(parameterName, params[parameterName])
        })

        return fetch(`${apiUrl}get-chats?${searchParameters}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${CookieController.get('_token')}`
            }
        })
        .then(handleSuccess)
        .catch(e => {
            console.error('chats api "getChats" error:', e)
            return handleError()
        })
    },
    getMessages: function(params) {
        let searchParameters = new URLSearchParams()
            
        Object.keys(params).forEach(function(parameterName) {
            searchParameters.append(parameterName, params[parameterName])
        })

        return fetch(`${apiUrl}get-messages?${searchParameters}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${CookieController.get('_token')}`
            }
        })
        .then(handleSuccess)
        .catch(e => {
            console.error('chats api "getMessages" error:', e)
            return handleError()
        })
    },
    sendMessage: function(params) {
        return fetch(`${apiUrl}send-message`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${CookieController.get('_token')}`
            },
            body: JSON.stringify({...params, socketId: SocketController.getSocketId()})
        })
        .then(handleSuccess)
        .catch(e => {
            console.error('chats api "sendMessage" error:', e)
            return handleError()
        })
    },
    readMessages: function(params) {
        return fetch(`${apiUrl}read-messages`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${CookieController.get('_token')}`
            },
            body: JSON.stringify({...params, socketId: SocketController.getSocketId()})
        })
        .then(handleSuccess)
        .catch(e => {
            console.error('chats api "readMessages" error:', e)
            return handleError()
        })
    },
    sendTyping: function(params) {
        return fetch(`${apiUrl}send-typing`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${CookieController.get('_token')}`
            },
            body: JSON.stringify({...params})
        })
        .then(handleSuccess)
        .catch(e => {
            console.error('chats api "sendTyping" error:', e)
            return handleError()
        })
    },
}

export default apis