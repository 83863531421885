import React from 'react'
import authApi from '../../../apis/auth'
import countries from "countries-list";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as userActions from '../../../store/actions/user'
import { withRouter } from "react-router-dom"
import { getTimezoneOffset } from '../../../controllers/DateController';
import AuthStudentImg from '../../../assets/images/auth-student.svg'
import AuthTutorImg from '../../../assets/images/auth-tutor.svg'
import Step2Student from './Step2Student'
import Step2Tutor from './Step2Tutor';

const countryCodes = Object.keys(countries.countries);
const countryNames = countryCodes.map(code => countries.countries[code].name);

const steps = 3

class Register extends React.Component {
    state = {
        type: '',
        step: 1
    }

    render() {
        return <div className="auth-block container">
            <div className="steps">
                {Array.from(Array(steps).keys()).map((step) => {
                    let active = this.state.step >= (step+1)
                    let firstLine = step !== 0
                    let lastLine = step+1 !== steps
                    return <>
                        {firstLine && <span className={`line first ${active ? 'active' : ''}`}></span>}
                        <div className={`step ${active ? 'active': ''}`}>
                            {step+1}
                            {active && <span className="circle"></span>}
                        </div>
                        {lastLine && <span className={`line last ${active ? 'active' : ''}`}></span>}
                    </>
                })}
            </div>

            {this.state.isFetching && <div className="d-flex justify-content-center loader">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>}

            {this.state.step === 1 && <>
                <h2 style={{marginBottom: 15}}>SIGNUP</h2>

                <h3>Create your account</h3>
                
                <div className="auth-types">
                    <div 
                        className={`select-type`} 
                        onClick={() => {
                            this.setState({type: 'student', step: 2})
                        }}
                    >
                        <img src={AuthStudentImg} />
                        <h3>student</h3>
                    </div>
                    <div style={{width: 48}}></div>
                    <div 
                        className={`select-type`} 
                        onClick={() => {
                            this.setState({type: 'tutor', step: 2})
                        }}
                    >
                        <img src={AuthTutorImg} />
                        <h3>tutor</h3>
                    </div>
                </div>
            </>}

            {this.state.step === 2 && this.state.type === 'student' && <Step2Student />}
            {this.state.step === 2 && this.state.type === 'tutor' && <Step2Tutor />}
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Register))