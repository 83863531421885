import React from 'react'
import userApi from '../../apis/user'
import countries from "countries-list";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as userActions from '../../store/actions/user'
import { withRouter } from "react-router-dom"
import SimpleSelect from '../../components/inputs/SimpleSelect';
import Avatar from '../../components/profile/Avatar';

const languagesCodes = Object.keys(countries.languages);
const languagesNames = languagesCodes.map(code => countries.languages[code].name);

class EditProfile extends React.Component {
    state = {
        description: this.props.user.description,
        gender: Number(this.props.user.gender),
        dateOfBirth: this.props.user.dateOfBirth,
        native: this.props.user.native,
        fluent: this.props.user.fluent,
        paypalEmail: this.props.user.paypalEmail,
        email: this.props.user.email,
        errors: []
    }

    submit = (e) => {
        e.preventDefault();

        if(this.state.isFetching)
            return

        this.setState({isFetching: true})
        
        userApi.editProfile({
            description: this.state.description,
            gender: this.state.gender,
            dateOfBirth: this.state.dateOfBirth,
            fullName: this.state.fullName,
            native: this.state.native,
            fluent: this.state.fluent,
            paypalEmail: this.state.paypalEmail,
            email: this.state.email
        }).then(response => {
            if(response.success) {
                window.location.href = `/profiles/${this.props.user._id}`
            } else {
                this.setState({errors: response.errors})
            }
            this.setState({isFetching: false})
        })
    }

    render() {
        return <div className="auth-block">
            <form className="container" onSubmit={(e) => {this.submit(e)}}>
                {this.state.isFetching && <div className="d-flex justify-content-center loader">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>}

                <h2 style={{marginBottom: 20}}>EDIT PROFILE</h2>

                <div className="col-md-12">
                    <div className="row">
                        <div className="col-lg-6" style={{flexDirection: 'row', alignItems: 'center', display: 'flex'}}>
                            <Avatar user={this.props.user} size={160} />
                            <div>
                                <label style={{fontSize: 16}} className="button btn-empty" style={{marginLeft: 36}}>
                                    Upload
                                    <input 
                                        type="file"
                                        onChange={(e) => {this.props.userActions.updateAvatar(e)}}
                                        style={{display: 'none'}} 
                                        accept="image/jpeg,image/png,image/jpg" 
                                    />
                                </label>
                                <button className="button btn-cancel" onClick={(e) => {
                                    e.preventDefault()
                                    this.props.userActions.removeAvatar()
                                }}>Remove</button>
                                <label className="form-label" style={{margin: '12px 0 0 36px', fontSize: 16, textTransform: 'uppercase'}}>Please make sure its clear</label>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <label className="form-label">ABOUT ME</label>
                            <textarea style={{height: 260}} value={this.state.description} placeholder="This is the first thing people read about you when considering working with you.. make it impressive!" onChange={(e) => {
                                this.setState({description: e.target.value})
                            }} className="input-simple"></textarea>
                        </div>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6">
                            <label className="form-label">DATE OF BIRTH *</label>
                            <input type="date" defaultValue={new Date(this.state.dateOfBirth).toISOString().substr(0,10)} min={new Date(new Date().setFullYear('1900')).toISOString().substr(0,10)} max={new Date().toISOString().substr(0,10)} onChange={(e) => {
                                if(new Date(e.target.value) !== undefined) {
                                    this.setState({dateOfBirth: e.target.value})
                                }
                            }} className="input-simple" />
                            {this.state.errors.filter((error) => error.param === 'dateOfBirth').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">GENDER *</label>
                            <SimpleSelect 
                                value={this.state.gender}
                                values={[
                                    {value: 1, label: 'Male'}, 
                                    {value: 2, label: 'Female'}, 
                                ]} 
                                onClick={(value) => {
                                    this.setState({gender: value})
                                }}
                            />
                            {this.state.errors.filter((error) => error.param === 'gender').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}
                        </div>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6">
                            <label className="form-label">NATIVE LANGUAGE *</label>
                            <SimpleSelect 
                                value={this.state.native}
                                values={[
                                    {label: 'English', value: 'English'},
                                    ...languagesNames.map(language => ({value: language, label: language})).filter(x => x.label !== 'English')
                                ]} 
                                onClick={(value) => {
                                    this.setState({native: value})
                                }}
                            />
                            {this.state.errors.filter((error) => error.param === 'native').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">FLUENT LANGUAGE</label>
                            <SimpleSelect 
                                value={this.state.fluent}
                                values={[
                                    {value: '', label: 'Not chosen'},
                                    ...languagesNames.map(language => ({value: language, label: language}))
                                ]} 
                                onClick={(value) => {
                                    this.setState({fluent: value})
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6 col-md-12">
                            <label className="form-label">EMAIL *</label>
                            <input value={this.state.email} className="input-simple" type="text" placeholder="Your paypal email" onChange={(e) => {
                                this.setState({email: e.target.value})
                            }} />
                            {this.state.errors.filter((error) => error.param === 'email').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}
                        </div>
                    </div>
                </div>

                {this.props.user.type === 'tutor' && <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6 col-md-12">
                            <label className="form-label">PAYPAL EMAIL *</label>
                            <input value={this.state.paypalEmail} className="input-simple" type="text" placeholder="Your paypal email" onChange={(e) => {
                                this.setState({paypalEmail: e.target.value})
                            }} />
                            {this.state.errors.filter((error) => error.param === 'paypalEmail').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}
                        </div>
                    </div>
                </div>}

                <button style={{margin: '22px auto', display: 'block', height: 60, fontSize: 18, width: 236, fontWeight: '50'}} className="button btn-filled">Save</button>
            </form>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(EditProfile))