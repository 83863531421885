import React from 'react'
import { connect } from 'react-redux'
import { getTimezoneOffset } from '../../controllers/DateController'
import Schedule from '../tutor/Schedule'
import userApi from '../../apis/user'
import lessonApi from '../../apis/lesson'

class ChangeTimeModal extends React.Component {
    state = {
        user: false,
        errors: [],
        isFetching: true
    }
    componentDidMount() {
        userApi.getUser({_id: this.props.data.userId}).then(response => {
            if(response.success) {
                this.setState({user: response.user})
            } else {
                this.props.onClose()
            }

            this.setState({isFetching: false})
        })
    }

    changeTime(time) {
        lessonApi.changeTime({_id: this.props.data.lesson._id, time}).then(response => {
            if(response.success) {
                window.location.reload()
            } else {
                this.setState({errors: response.errors})
            }
        })
    }

    render() {
        return <div className="book-modal modal" onClick={() => {
            this.props.onClose()
        }}>
            <div className="modal-dialog" onClick={(e) => {
                e.stopPropagation()
            }}>
                {this.state.isFetching && <div className="d-flex justify-content-center loader">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>}
                {!this.state.isFetching && <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Change a time</h5>
                        {/* <button type="button" className="btn-close" onClick={() => {
                            this.props.onClose()
                        }}></button> */}
                    </div>
                    <div className="modal-body">
                    <Schedule 
                        bookLesson={(clickTime) => {
                            this.changeTime(clickTime)
                        }}
                        withDate={true} 
                        schedule={this.state.user.schedule}
                        lessons={this.state.user.lessons}
                        time={this.state.user.time}
                        gmtDiff={Number(this.props.myUser.isAuth ? this.props.myUser.gmt : getTimezoneOffset()) - Number(this.state.user.gmt)} 
                    />
                    {this.state.errors.filter((error) => error.param === 'all').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}
                    </div>
                </div>}
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        myUser: state.user
    }
  }
  
export default connect(
    mapStateToProps
)(ChangeTimeModal)