import React from 'react'
import tutorApi from '../../apis/tutor'
import TutorCard from '../../components/tutor/TutorCard'
import TutorFilters from '../../components/tutor/TutorFilters'
import Pagination from '../../components/Pagination'
import SearchIcon from '@material-ui/icons/Search';

class FindTutor extends React.Component {
    state = {
        tutors: [],
        pagination: {},
        isFetching: true,
    }

    componentDidMount() {
        // this.getTutors({page: 1})
    }

    getTutors = (params) => {
        this.setState({isFetching: true})
        
        tutorApi.getList({...params, perPage: 10}).then(response => {
            if(response.success) {
                this.setState({tutors: response.tutors, pagination: response.pagination})
            }

            this.setState({isFetching: false})
        })
    }

    onFilter = (filters) => {
        this.setState({filters})
        this.getTutors({
            page: 1,
            ...filters
        })
    }

    render() {
        return <div className="find-tutor container">
            <h1>FIND A TUTOR</h1>
            <TutorFilters onFilter={this.onFilter} />
            {this.state.isFetching && <div className="d-flex justify-content-center loader">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>}

            {!this.state.isFetching && !!this.state.tutors.length && this.state.tutors.map(tutor => {
                return <TutorCard tutor={tutor} key={tutor._id} />
            })}

            <Pagination paginate={(page) => {
                this.getTutors({page, filters: this.state.filters})
            }} pagination={this.state.pagination} currentPage={this.state.pagination.page} />

            {!this.state.isFetching && !this.state.tutors.length && <div className="empty-data">
                <SearchIcon /><br/>
                No results were found for your search.
            </div>}
        </div>
    }
}

export default FindTutor