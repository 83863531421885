import React from 'react'
import { withRouter } from "react-router-dom"
import { getDateWithMonth, days, getTimes } from '../../controllers/DateController'
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import '../../assets/styles/pickTime.css'
import SimpleBar from 'simplebar-react';

function getWeek(curr) {
    curr = new Date(curr)
    let week = []

    for (let i = 1; i <= 7; i++) {
        let first = curr.getDate() - curr.getDay() + i 
        let day = new Date(curr.setDate(first)).toISOString().slice(0, 10)
        week.push(day)
    }

    return week
}

class Schedule extends React.Component {
    state = {
        week: getWeek(new Date()),
        currentDate: new Date(),
        schedule: this.props.schedule,
    }
    
    componentDidMount() {
        let secondsDiff = this.props.gmtDiff * 60 * 60
        let newSchedule = this.state.schedule

        newSchedule = newSchedule.map(sch => ({...sch, start: sch.start - secondsDiff, end: sch.end - secondsDiff}))
        

        while (true) {
            let checkScopeStart = newSchedule.find(sch => sch.start < 0)
            let checkScopeEnd = newSchedule.find(sch => sch.end > 86400)

            if(!!checkScopeStart) {
                let insertPrev = {
                    day: checkScopeStart.day > 1 ? 
                        checkScopeStart.day - 1 : 
                        7, 
                    start: 86400 + checkScopeStart.start, 
                    end: checkScopeStart.end < 0 ? 86400 + checkScopeStart.end : 86400
                }

                newSchedule.push(insertPrev)
                if(checkScopeStart.end < 0)
                    newSchedule = newSchedule.filter(sch => !(sch.day === checkScopeStart.day && sch.start === checkScopeStart.start && sch.end === checkScopeStart.end))
                else
                    newSchedule = newSchedule.map(sch => sch.day === checkScopeStart.day && sch.start === checkScopeStart.start && sch.end === checkScopeStart.end ? {...sch, start: 0} : sch)
            }

            if(!!checkScopeEnd) {
                let insertNext = {
                    day: checkScopeEnd.day < 7 ? 
                        checkScopeEnd.day + 1 : 
                        1, 
                    start: checkScopeEnd.start > 86400 ? checkScopeEnd.start - 86400  : 0, 
                    end: checkScopeEnd.end - 86400
                }

                newSchedule.push(insertNext)
                if(checkScopeEnd.start > 86400)
                    newSchedule = newSchedule.filter(sch => !(sch.day === checkScopeEnd.day && sch.start === checkScopeEnd.start && sch.end === checkScopeEnd.end))
                else
                    newSchedule = newSchedule.map(sch => sch.day === checkScopeEnd.day && sch.start === checkScopeEnd.start && sch.end === checkScopeEnd.end ? {...sch, end: 86400} : sch)
            }

            if(!checkScopeEnd && !checkScopeStart) {
                break
            }
        }

        this.setState({
            schedule: newSchedule
        })
    }

    nextWeek = () => {
        let lastDateOfWeek = new Date(this.state.week[6])
        this.setState({
            week: getWeek(lastDateOfWeek.setDate(lastDateOfWeek.getDate() + 1))
        })
    }

    prevWeek = () => {
        let firstDateOfWeek = new Date(this.state.week[0])
        this.setState({
            week: getWeek(firstDateOfWeek.setDate(firstDateOfWeek.getDate() - 6))
        })
    }


    render() {
        return <div className="schedule-container">
            <div className="title">
                <button type="button" onClick={this.prevWeek} disabled={this.state.currentDate > new Date(this.state.week[0])}><NavigateBeforeIcon fontSize={'large'} /></button>
                <div className="col"></div>
                {getDateWithMonth(this.state.week[0])} - {getDateWithMonth(this.state.week[6])}
                <div className="col"></div>
                <button type="button" onClick={this.nextWeek}><NavigateNextIcon fontSize={'large'} /></button>
            </div>

            <SimpleBar className="schedule">
                <div className="head">
                    {days.map((day, i) => {
                        let current = new Date()
                        let active = this.state.week[i] === `${current.getFullYear()}-${current.getMonth()+1 > 9 ? current.getMonth()+1 : '0' + (current.getMonth() + 1)}-${current.getDate() > 9 ? current.getDate() : '0' + current.getDate()}`
                        let currentDate = new Date(this.state.week[i])
                        return <div className={`day-name ${active ? 'active' : ''}`} key={day}>
                            {day.slice(0, 2)}
                            <div className="day-date">{currentDate.getDate()}</div>
                        </div>
                    })}
                </div>

                <SimpleBar className="body">
                    {days.map((dayName, i) => {
                        let scheduleDay = this.state.schedule.filter(sch => sch.day === i+1)

                        let showDay = true
                        let current = new Date()
                        let active = new Date(this.state.week[i]).setHours(0, 0)

                        if(current >= active)
                            showDay = false

                        return <div className="day col" key={dayName}>
                            <div className="times" style={{display: 'block'}}>
                                {getTimes().map((time) => {
                                    let checkTime = scheduleDay.filter(sch => sch.start <= time && sch.end >= time)

                                    if(!checkTime.length)
                                        return null

                                    let isDisabled = false

                                    if(!showDay)
                                        isDisabled = true

                                    let hour = Math.floor(time/60/60)
                                    let minutes = Math.floor((time-hour*60*60)/60)

                                    let clickTime = active + (hour * 60 * 60 * 1000) + (minutes * 60 * 1000)
                                    let endTime = clickTime + (Number(this.props.time) * 60 * 1000)

                                    let existLesson = this.props.lessons.find(lesson => 
                                        ((lesson.startTime <= clickTime && lesson.endTime >= clickTime) 
                                        || 
                                        (lesson.startTime <= endTime && lesson.endTime >= endTime))
                                    )

                                    if(!!existLesson)
                                        isDisabled = true

                                    return <div onClick={() => {
                                        if(isDisabled)
                                            return

                                        this.props.bookLesson(clickTime, this.props.subject)
                                    }} className={`time ${isDisabled ? 'disabled' : ''}`} key={time}>
                                        {hour < 10 ? `0${hour}` : hour}:{minutes < 10 ? `0${minutes}` : minutes}
                                    </div>
                                })}
                            </div>
                        </div>
                    })}
                </SimpleBar>
            </SimpleBar>
        </div>
    }
}


export default withRouter(Schedule)