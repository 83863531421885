import React from "react";
// Router
import { Switch, Route, Redirect, withRouter } from "react-router-dom"
import routes from './config'

// Redux
import { connect } from "react-redux"
import * as userActions from "../store/actions/user"
import { bindActionCreators } from "redux"
 
// Other functions
import { setTitle } from "../controllers/FunctionsController"
import CookieController from "../controllers/CookieController"
import userApi from "../apis/user"
// import SocketController from "../Controllers/SocketController"

class AppRouter extends React.Component {
    state = {
        isRender: false
    }

    componentDidUpdate() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    componentDidMount() {
        this.props.history.listen(() => {
            setTitle(this.props.history.location.pathname, routes)
        })
        
        setTitle(this.props.history.location.pathname, routes)

        let _token = CookieController.get('_token')
        
        if(_token) {
            userApi.me().then(({user, success}) => {
                if(success) {
                    this.props.userActions.loginUser(user, _token)
                    this.setState({isRender: true})
                } else {
                    this.setState({isRender: true})
                }
            })
        } else {
            this.setState({isRender: true})
        }
    }

    render() {
        return this.state.isRender && (<>
            <Switch>
                {routes.map((route, index) => {
                    switch (route.type) {
                        case 'auth':
                            return <this.AuthRoute
                                key={index}
                                path={route.path}
                                exact={route.exact}
                            >
                                <route.component />
                            </this.AuthRoute>
                        case 'all':
                            return <this.AllRoute
                                key={index}
                                path={route.path}
                                exact={route.exact}
                            >
                                <route.component />
                            </this.AllRoute>
                        case 'confirm':
                            return <this.ConfirmRoute
                                key={index}
                                path={route.path}
                                exact={route.exact}
                            >
                                <route.component />
                            </this.ConfirmRoute>
                        case 'create_profile':
                            return <this.CreateProfile
                                key={index}
                                path={route.path}
                                exact={route.exact}
                            >
                                <route.component />
                            </this.CreateProfile>
                        case 'upload_certificates':
                            return <this.UploadCertificatesRoute
                                key={index}
                                path={route.path}
                                exact={route.exact}
                            >
                                <route.component />
                            </this.UploadCertificatesRoute>
                        case 'create_lesson':
                            return <this.CreateLessonRoute
                                key={index}
                                path={route.path}
                                exact={route.exact}
                            >
                                <route.component />
                            </this.CreateLessonRoute>
                        case 'schedule':
                            return <this.ScheduleRoute
                                key={index}
                                path={route.path}
                                exact={route.exact}
                            >
                                <route.component />
                            </this.ScheduleRoute>
                        case 'user':
                            return <this.UserRoute
                                key={index}
                                path={route.path}
                                exact={route.exact}
                            >
                                <route.component />
                            </this.UserRoute>
                        default:
                            return false
                    }            
                })}

                <Route component={() =>
                    <Redirect
                        to={{
                            pathname: "/",
                        }}
                    />
                } />
            </Switch>
        </>)
    }

    AllRoute = ({ children, ...rest }) => {
        return (
            <Route
                {...rest}
                render={() =>
                    (
                        children
                    )
                }
            />
        )
    }

    AuthRoute = ({ children, ...rest }) => {
        return (
            <Route
                {...rest}
                render={() =>
                    !this.props.user.isAuth ? (
                        children
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/",
                            }}
                        />
                    )
                }
            />
        )
    }

    UserRoute = ({ children, ...rest }) => {
        return (
            <Route
                {...rest}
                render={() =>
                    this.props.user.isAuth ? 
                        this.props.user.isConfirmed ? 
                            (this.props.user.status === 'create_profile') ? (
                                <Redirect
                                    to={{
                                        pathname: "/create-profile",
                                    }}
                                />
                            )
                            : (
                                (this.props.user.status === 'upload_certificates') ? 
                                    (<Redirect
                                        to={{
                                            pathname: "/upload-certificates",
                                        }}
                                    />) : 
                                    (this.props.user.status === 'create_lesson') ? 
                                    (<Redirect
                                        to={{
                                            pathname: "/create-lesson",
                                        }}
                                    />) : 
                                    (this.props.user.status === 'set_schedule') ? 
                                    (<Redirect
                                        to={{
                                            pathname: "/set-schedule",
                                        }}
                                    />) : 
                                    (children)
                            )
                        : (
                            <Redirect
                                to={{
                                    pathname: "/confirm",
                                }}
                            />
                        )
                    : (
                        <Redirect
                            to={{
                                pathname: "/",
                            }}
                        />
                    )
                }
            />
        )
    }

    ConfirmRoute = ({ children, ...rest }) => {
        return (
            <Route
                {...rest}
                render={() =>
                    this.props.user.isAuth && !this.props.user.isConfirmed ? (
                        children
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/",
                            }}
                        />
                    )
                }
            />
        )
    }

    CreateProfile = ({ children, ...rest }) => {
        return (
            <Route
                {...rest}
                render={() =>
                    this.props.user.isAuth && this.props.user.status === 'create_profile' ? (
                        children
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/",
                            }}
                        />
                    )
                }
            />
        )
    }

    CreateLessonRoute = ({ children, ...rest }) => {
        return (
            <Route
                {...rest}
                render={() =>
                    this.props.user.isAuth && this.props.user.type === 'tutor' && (this.props.user.status === 'create_lesson' || this.props.user.status === 'completed') ? (
                        children
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/",
                            }}
                        />
                    )
                }
            />
        )
    }

    UploadCertificatesRoute = ({ children, ...rest }) => {
        return (
            <Route
                {...rest}
                render={() =>
                    this.props.user.isAuth && this.props.user.type === 'tutor' && !this.props.user.isAdmitted && this.props.user.status === 'upload_certificates' ? (
                        children
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/",
                            }}
                        />
                    )
                }
            />
        )
    }
    
    ScheduleRoute = ({ children, ...rest }) => {
        return (
            <Route
                {...rest}
                render={() =>
                    this.props.user.isAuth && this.props.user.type === 'tutor' && !this.props.user.isSchedule ? (
                        children
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/",
                            }}
                        />
                    )
                }
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AppRouter))
