import React from 'react'
import userApi from '../../../apis/user'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom"
import categoryApi from '../../../apis/category'
import CloseIcon from '@material-ui/icons/Close';
import SimpleSelect from '../../../components/inputs/SimpleSelect'

class UploadCertificates extends React.Component {
    state = {
        categories: [],
        errors: [],
        addCertificates: [{
            
        }],
        countCert: 1,
        isFetching: true
    }

    submit = (e, i) => {
        e.preventDefault();

        if(this.state.isFetching)
            return

        this.setState({isFetching: true})

        let formData = new FormData()

        for (let i = 0; i < Array.from(Array(this.state.countCert).keys()).length; i++) {
            if(!this.state.addCertificates[i] || (!!this.state.addCertificates[i] && !this.state.addCertificates[i].certificate))
                return this.setState({isFetching: false, errors: [{param: 'certificate'+i, msg: `Certificate must be not empty`}]})
            
            if(!this.state.addCertificates[i].category || this.state.addCertificates[i].category === '')
                return this.setState({isFetching: false, errors: [{param: 'certificate'+i, msg: `Select category`}]})

            formData.append('certificate'+(i+1), this.state.addCertificates[i].certificate.file)
            formData.append('category'+(i+1), this.state.addCertificates[i].category)
        }
        
        
        userApi.admit(formData).then(response => {
            if(response.success) {
                window.location.reload()
            } else {
                this.setState({errors: response.errors})
            }

            this.setState({isFetching: false})
        })
    }

    componentDidMount() {
        categoryApi.getCategories({}).then((response) => {
            if(response.success) {
                this.setState({
                    categories: response.categories.sort(function(a, b){
                        if(a.name < b.name) { return -1; }
                        if(a.name > b.name) { return 1; }
                        return 0;
                    }),
                    isFetching: false,
                })
            }
        })
    }

    render() {
        return <div className="auth-block">
            {!this.props.user.isUploadedCertificate && <div className="auth-card" style={{width: 460}}>
                {this.state.isFetching && <div className="d-flex justify-content-center loader">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>}

                <h2>Send your certificates</h2>

                <p style={{textAlign: 'center', color: '#6C6C6C', fontSize: 22}}>
                    We need your certificates to confirm knowledge, after which your account will be verified and you will be available for search.
                </p>

                <a href="mailto:hey@thetutor.link" style={{margin: '10px 0 20px'}} className="button btn-filled btn-full">Request A New Category</a>

                <p style={{textAlign: 'center', color: '#6C6C6C', fontSize: 22}}>
                    Aviable types: <b>pdf, jpg, jpeg, png</b>.
                </p>
                
                <form onSubmit={this.submit}>
                    {Array.from(Array(this.state.countCert).keys()).map((item, i) => {
                        i = i + 1

                        return <div className="mb-3">
                            <label className="form-label">
                                Your cerificate #{i}
                                <div className="col"></div>
                                {this.state.countCert > 1 && <div onClick={() => {
                                    this.setState({countCert: this.state.countCert - 1, addCertificates: this.state.addCertificates.filter(
                                        (cert, index) => index !== i-1 
                                    )})
                                }}>
                                    <CloseIcon />
                                </div>}
                            </label>
                            <SimpleSelect 
                                value={this.state.addCertificates[i-1].category}
                                values={this.state.categories.map(category => {
                                    return { value: category._id, label: category.name}
                                })} 
                                onClick={(value) => {
                                    this.setState({addCertificates: this.state.addCertificates.map(
                                        (cert, index) => index === i-1 ?
                                        {...cert, category: value} : 
                                        cert
                                    )})
                                }}
                            />
                            <input 
                                style={{marginTop: 15, padding: '6px 12px', height: 'max-content', boxShadow: 'none'}}
                                className="form-control input-simple" 
                                type="file"
                                onChange={(e) => {
                                    let certificate = {
                                        path: (window.URL || window.webkitURL).createObjectURL(new Blob([e.target.files[0]], {type: e.target.files[0].type})), 
                                        file: e.target.files[0], 
                                        name: e.target.files[0].name, 
                                        type: e.target.files[0].name.split('.').pop(),
                                        size: e.target.files[0].size / 1000
                                    }

                                    this.setState({addCertificates: this.state.addCertificates.map(
                                        (cert, index) => index === i-1 ?
                                        {...cert, certificate} : 
                                        cert
                                    )})
                                }}
                            />
                            {this.state.errors.filter((error) => error.param === 'certificate'+(i-1)).map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}
                        </div>
                    })}

                    {this.state.countCert < 10 && <div style={{display: 'flex', justifyContent: 'center', marginBottom: 15}}>
                        <button className="button btn-empty" onClick={(e) => {
                            e.preventDefault()
                            this.setState({
                                countCert: this.state.countCert+1, 
                                addCertificates: [...this.state.addCertificates, {certificate: false, category: ''}]
                            })
                        }}>Add more</button>
                    </div>}
                    
                    {this.state.errors.filter((error) => error.param === 'all').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}

                    <button className="button btn-filled" style={{margin: '0 auto', display: 'block'}} type="submit">Send</button>
                </form>
            </div>}

            {this.props.user.isUploadedCertificate && <div className="auth-card">
                <h2>Under consideration</h2>

                <p style={{textAlign: 'center', color: '#6C6C6C', fontSize: 22}}>
                    At the moment we are checking your certificate, after confirmation your account will be admitted.
                </p>
            </div>}
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(
    mapStateToProps
)(withRouter(UploadCertificates))