import React from 'react'
import reviewApi from '../../apis/review'
import ReviewCard from './ReviewCard'
import Pagination from '../Pagination'
import SearchIcon from '@material-ui/icons/Search';

class Reviews extends React.Component {
    state = {
        reviews: [],
        pagination: {},
        isFetching: true,
    }

    componentDidMount() {
        this.getReviews({page: 1})
    }

    getReviews = (params) => {
        this.setState({isFetching: true})
        
        reviewApi.getList({...params, perPage: 10, userId: this.props.userId}).then(response => {
            if(response.success) {
                this.setState({reviews: response.reviews, pagination: response.pagination})
            }

            this.setState({isFetching: false})
        })
    }

    render() {
        return <div className="col-lg-12 col-md-12" style={{position: 'relative'}}>
            <div className="title">
                Reviews
            </div>

            {this.state.isFetching && <div className="d-flex justify-content-center loader">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>}

            {!this.state.isFetching && !!this.state.reviews.length && this.state.reviews.map(review => {
                return <ReviewCard review={review} key={review._id} />
            })}

            <Pagination paginate={(page) => {
                this.getReviews({page})
            }} pagination={this.state.pagination} currentPage={this.state.pagination.page} />

            {!this.state.isFetching && !this.state.reviews.length && <div style={{minHeight: 200, width: '100%'}}>
                <div className="empty-data">
                    <SearchIcon /><br/>
                    No reviews yet.
                </div>
            </div>}
        </div>
    }
}

export default Reviews