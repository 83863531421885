import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom"
import Avatar from '../profile/Avatar'
import DoneAllIcon from '@material-ui/icons/DoneAll';
import DoneIcon from '@material-ui/icons/Done';
import { lastMessageAt } from '../../controllers/DateController';

class DialogItem extends React.Component {
    render() {
        return <>
            <div className={`chat-dialog-item ${`/chats/${this.props.chat.user._id}` === window.location.pathname ? 'active' : ''}`} onClick={() => {
                if(`/chats/${this.props.chat.user._id}` !== window.location.pathname)
                    this.props.history.push(`/chats/${this.props.chat.user._id}`)
            }}>
                <Avatar user={this.props.chat.user} size={60} isOnline={this.props.chat.user.isOnline} />
                <div className="info">
                    <div className="name">{this.props.chat.user.fullName.split(' ')[0]}</div>
                    {!this.props.chat.isTyping && <div className="message">{this.props.chat.lastMessage.text}</div>}
                    {this.props.chat.isTyping && <div className="message typing">Typing</div>}
                </div>
                <div className="col"></div>
                <div className="add-info">
                    <div className="time">{lastMessageAt(this.props.chat.lastMessage.createdAt)}</div>
                    <div className="status">
                        {this.props.user._id !== this.props.chat.lastMessage.user._id && this.props.chat.noRead > 0 && <span className="badge rounded-pill">
                            {this.props.chat.noRead}
                            <span className="visually-hidden">unread messages</span>
                        </span>}

                        {this.props.chat.noRead === 0 && <span className="badge rounded-pill" style={{opacity: '0'}}>
                            {this.props.chat.noRead}
                            <span className="visually-hidden">unread messages</span>
                        </span>}
                    </div>
                </div>
            </div>
            <div className="chat-seperator"></div>
        </>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

export default connect(mapStateToProps)(withRouter(DialogItem))