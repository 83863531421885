import { handleError, handleSuccess } from "."
import CookieController from "../controllers/CookieController"

let apiUrl = `${process.env.REACT_APP_API_URL}/api/review/`

const apis = {
    sendReview: function(params) {
        return fetch(`${apiUrl}send-review`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${CookieController.get('_token')}`
            },
            body: JSON.stringify(params)
        })
        .then(handleSuccess)
        .catch(e => {
            console.error('review api "sendReview" error:', e)
            return handleError()
        })
    },
    getList: function(params) {
        let searchParameters = new URLSearchParams()
            
        Object.keys(params).forEach(function(parameterName) {
            searchParameters.append(parameterName, params[parameterName])
        })

        return fetch(`${apiUrl}get-list?${searchParameters}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
        .then(handleSuccess)
        .catch(e => {
            console.error('review api "getList" error:', e)
            return handleError()
        })
    },
}

export default apis