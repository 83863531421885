import countries from 'countries-list';
import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Rating from '../tutor/Rating';
import Avatar from './Avatar';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import { bindActionCreators } from 'redux';
import * as userActions from '../../store/actions/user'
import PublishIcon from '@material-ui/icons/Publish';
import SelectSchedule from '../tutor/SelectSchedule';
import Reviews from '../review/Reviews';
import Subjects from '../subject/Subjects';

class MyProfile extends React.Component {
    state = {
        updateDescription: false,
        description: this.props.user.description
    }

    render() {
        return <div className="container">
            <div className="row">
           <div className="profile-info col-lg-3 col-md-4">
                    <Avatar user={this.props.user} size={174} />

                    <div className="name">
                        {this.props.user.fullName}
                    </div>

                    <Rating rating={this.props.user.rating} />

                    <>
                        <button style={{marginBottom: 15}} className="button btn-filled btn-full" onClick={() => {
                            this.props.history.push(`/edit-profile`)
                        }}>Edit profile</button>
                        <button style={{width: '100%', margin: 0, marginBottom: 30}} className="button btn-cancel" onClick={() => {
                            this.props.userActions.logoutUser()
                        }}>Logout</button>
                    </>

                    <div className="specs">
                        <div className="row">
                            From<div className="col"></div><span>{this.props.user.country}</span>
                        </div>
                        <div className="row">
                            Native language<div className="col"></div><span>{this.props.user.native}</span>
                        </div>
                        {!!this.props.user.fluent && <div className="row">
                            Fluent language<div className="col"></div><span>{this.props.user.fluent}</span>
                        </div>}
                    </div>

                    {!!this.props.user.description && <>
                        <div style={{width: '100%', border: '1px solid rgba(0, 0, 0, 0.2)', margin: '10px 0'}} />

                        <div className="about-heading">About Me</div>
                    </>}

                    <div className="about-text">{this.props.user.description}</div>

                    <div style={{width: '100%', border: '1px solid rgba(0, 0, 0, 0.2)', margin: '10px 0'}} />

                    <div className="since-heading">Member since</div>

                    <div className="since-year">{new Date(this.props.user.createdAt).getFullYear()}</div>
            </div>

             <div className="col-lg-9 col-md-8">
                {this.props.user.type === 'tutor' && <Subjects userId={this.props.user._id} onBook={subject => {
                    this.setState({subjectModal: subject})
                }} />}

                {this.props.user.type === 'tutor' && <>
                    <div className="title">
                        Schedule
                    </div>

                    <SelectSchedule />
                 </>}

                <Reviews userId={this.props.user._id} />
            </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
    }
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(MyProfile))