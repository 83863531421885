import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import section1 from '../../assets/images/bg-whe.jpg'
import section2icon1 from '../../assets/images/section2icon1.svg'
import section2icon2 from '../../assets/images/section2icon2.svg'
import section2icon3 from '../../assets/images/section2icon3.svg'
import section3 from '../../assets/images/section3.png'
import section4 from '../../assets/images/section4.png'
import section5 from '../../assets/images/section5.png'
import StepOneImg from '../../assets/images/stepOne.png'
import sectionhome1 from '../../assets/images/sectionhome 1.jpg'
import sectionhome2 from '../../assets/images/sectionhome 2.jpg'
import testimonials_user from '../../assets/images/Matilda Jennings.jpg'
import testimonials_user1 from '../../assets/images/Alicia Henderson.jpg'
import StepTwoImg from '../../assets/images/stepTwo.png'
import StepThreeImg from '../../assets/images/stepThree.png'
import rocket from '../../assets/images/rocket.svg'
import logo from '../../assets/images/Logo.svg'

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';





const checkIcon = {
    section2icon1: <svg width="46" height="50" viewBox="0 0 46 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.5379 19.9653C25.4263 19.9653 27.5568 17.8205 27.5568 14.9069C27.5568 11.9933 25.4263 9.84851 22.5379 9.84851C19.652 9.84851 17.5189 11.9933 17.5189 14.9069C17.5189 17.8205 19.652 19.9653 22.5379 19.9653ZM22.5379 22.4944C16.9945 22.4944 12.5 26.5715 12.5 31.5995V32.5758H32.5758V31.5995C32.5758 26.5715 28.0813 22.4944 22.5379 22.4944Z" fill="#7722BC"/>
    <path d="M15.3785 42.1461L15.2321 42H15.0253H5.00842C2.52162 42 0.5 39.9806 0.5 37.5V5C0.5 2.51943 2.52162 0.5 5.00842 0.5H40.0673C42.5541 0.5 44.5758 2.51943 44.5758 5V37.5C44.5758 39.9806 42.5541 42 40.0673 42H30.0505H29.8436L29.6972 42.1461L22.5379 49.2935L15.3785 42.1461ZM22.1846 42.8539L22.5379 43.2065L22.8911 42.8539L27.7532 38H40.0723H40.5724L40.5723 37.4999L40.5673 4.99992L40.5673 4.5H40.0673H5.00842H4.50842V5V37.5V38H5.00842H17.3226L22.1846 42.8539Z" fill="#7722BC" stroke="#F8FAFA"/>
    </svg>
    
  };
const checkIcon1 = {
    section2icon2: <svg xmlns="http://www.w3.org/2000/svg" width="48" height="50" viewBox="0 0 48 50" fill="none">
    <path d="M24 21.6842C18.4635 21.6842 14 17.2416 14 11.8421C14 6.44262 18.4635 2 24 2C29.5365 2 34 6.44262 34 11.8421C34 17.2416 29.5365 21.6842 24 21.6842ZM46 47.3684V48H45.3333H2V47.3684C2 38.3424 9.45303 30.9474 18.6667 30.9474H29.3333C38.5445 30.9474 46 38.3426 46 47.3684Z" stroke="#7722BC" stroke-width="4"/>
    </svg>
    
  };
  
const checkIcon2 = {
    section2icon3: <svg xmlns="http://www.w3.org/2000/svg" width="53" height="50" viewBox="0 0 53 50" fill="none">
    <path d="M9.05302 0L6.40464 2.64355L10.3452 6.58068L12.9899 3.93713L9.05114 0H9.05302ZM43.6155 0L39.6749 3.93713L42.3214 6.58068L46.2601 2.64543L43.6136 0H43.6155ZM26.3333 1.17324C25.7126 1.18076 25.0844 1.21649 24.4524 1.29357C24.4336 1.29357 24.4148 1.28981 24.396 1.29357C16.7668 2.16786 10.6951 8.32926 9.63988 15.9215C8.79721 22.0321 11.3177 27.573 15.5179 31.1417C17.2359 32.6073 18.398 34.6186 18.8095 36.8386V48.1198H23.0981C23.7527 49.2423 24.9471 50 26.3333 50C27.7196 50 28.914 49.2423 29.5686 48.1198H33.8571V40.599H34.034V38.3654C34.034 35.609 35.4672 32.8301 37.7959 30.6697C40.9107 27.5524 43.2619 23.1245 43.2619 18.0386C43.2619 8.75042 35.614 1.08675 26.3333 1.17324ZM26.3333 4.93363C33.6032 4.8321 39.5 10.781 39.5 18.0386C39.5 21.9757 37.6755 25.4428 35.1494 27.966L35.2095 28.0262C32.6992 30.3401 31.0759 33.4577 30.62 36.8405H22.3927C21.9789 33.6179 20.5964 30.4986 17.9838 28.2612C14.6601 25.4409 12.6795 21.1917 13.3416 16.3934C14.1636 10.4708 18.9713 5.70827 24.8624 5.05584C25.3488 4.98806 25.8386 4.94788 26.3296 4.93551L26.3333 4.93363ZM0 18.0386V21.799H5.64286V18.0386H0ZM47.0238 18.0386V21.799H52.6667V18.0386H47.0238ZM10.3452 33.2569L6.40652 37.1921L9.05302 39.8375L12.988 35.9004L10.3452 33.2569ZM42.3214 33.2569L39.6768 35.9004L43.6136 39.8375L46.2601 37.1921L42.3214 33.2569ZM22.5714 40.6009H30.0952V44.3613H22.5714V40.6009Z" fill="#7722BC"/>
    </svg>
    
};

class Home extends React.Component {
    render() {
        return <div className="home">
            <div className="grey-background">
                <div className="container">
                    <div className="section one row">
                        <div className="col-md-6 p-4">
                        
                            <h1>One to one <span>tutoring</span></h1>

                            <p>Our Fees Working With Vetted Tutors Is 5%.</p>

                            <p className="normal-font-size">Set Your Schedule, Create Lessons & Earn While Passing On The Gift Of Knowledge!</p>

                            <div className="row actions">
                                <button className="button btn-filled" onClick={() => {
                                    this.props.history.push(`/register`)
                                }}>Student Sign Up</button>
                                <button className="button btn-empty" onClick={() => {
                                    this.props.history.push(`/register`)
                                }}>Tutor Sign Up</button>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <img src={section1} />
                        </div>
                    </div>
                </div>            
            </div>
            <section className="pb-5 mt-5">
                    <div className="container pb-5">
                        <div className="card shadow text-center map-bg">
                        <div className="p-lg-5">
                            <div className="p-4">
                            <div className="row">
                                <div className="col-lg-7 mx-auto">

                                <p className="text-muted">The founders of this platform are parents of young children, so we know so well how important the online safety when it comes to our child.</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </section>

            {/* <div className="section two-new">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6 text-center">
                       
                            <p className="grey-text-color">The founders of this platform are parents of young children, so we know so well how important the online safety when it comes to our child.The founders of this platform are parents of young children, so we know so well how important the online safety when it comes to our child.</p>
                        </div>
                        <div className="col-md-3"></div>
                    </div>

                 <div className="row text-center mt-5 counter-section2">
                        <div className="col-md-4">
                            <h3>40,000+</h3>
                            <p className="grey-text-color">Teachers</p>
                        </div>
                        <div className="col-md-4">
                            <h3>100,000+</h3>
                            <p className="grey-text-color">Students</p>
                        </div>
                        <div className="col-md-4">
                            <h3>4</h3>
                            <p className="grey-text-color">Years in Business</p>
                        </div>
                    </div> 
                </div>
            </div> */}


                <section className="pb-5">
                    <div className="container pb-5">
                        <div className="card shadow text-center map-bg">
                        <div className="p-lg-5">
                            <div className="p-4">
                            <div className="row mb-5">
                                <div className="col-lg-7 mx-auto">
                                <p className="h6 text-primary text-uppercase">Thetutor.link</p>
                                <h2>Explore New & Interesting Subjects</h2>
                                <p className="text-muted">Learn something new and see where you interests take you or get that much needed help for an examination from an expert in the field.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 mb-3 mb-lg-0">
                                    <i className="fa fa-university fa-3x"></i>
                                <h5>Academic Tutoring</h5>
                                <p className="text-muted mb-0">Our most requested type of learning. From maths to English, Tutors at TheTutor.Link have not long done the very same exam you may have coming up!</p>
                                </div>
                                <div className="col-lg-4 mb-3 mb-lg-0">
                                    <i className="fa fa-code fa-3x"></i>
                                <h5>Professional / IT Related</h5>
                                <p className="text-muted mb-0">With vast experience across many subjects, you can find almost anything you would like to learn from the best! Business Coaches to HTML & WordPress Wizards, push start your next idea!</p>
                                </div>
                                <div className="col-lg-4">
                                    <i className="fa fa-money fa-3x"></i>
                                <h5>Instant Payment & Low Commission</h5>
                                <p className="text-muted mb-0">Earn your true potential with our 5% admin on every lesson! It is your knowledge & your time which helps others grow; good tutors as much :)</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </section>

            {/* <div className="container">
                <div className="shadow-tab">
                    <img src={logo} alt="TheTutor - learn all what you want." />
                    <p>welcomes Tutors to earn money by  teaching their knowledge as well as Students who want to acquire that knowledge.</p>

                    <div>
                        Learn something new today!
                    </div>
                </div>
            </div> */}

            <section className="">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-5 mx-auto mb-5 mb-lg-0 d-flex flex-column">
                        <div className="numeric-block order-2 order-lg-1 mb-lg-5">
                        <h3>Parents Looking For A Vetted Online Tutor For Your Child?</h3>
                        <p className="text-muted py-3">The founders of this platform are parents of young children, so we know so well how important the online safety when it comes to our child.

Every Tutor who applies to work on our platform is vetted by 2 separate Moderators of the site; we also carry out regular questionairres with students, tutors and parents (if the student is under the age of 18).

You can feel very safe having your child learn and advance their academic career when learning with TheTutor.Link.</p>
                        </div>
                        <img className="flex-grow-0 img-fluid shadow rounded order-1 order-lg-2 mb-5 mb-lg-0" src={sectionhome2} alt="" />
                    </div>
                    <div className="col-lg-5 mx-auto d-flex flex-column">
                        <img className="flex-grow-0 img-fluid shadow rounded mb-5" src={sectionhome1} alt="" />
                        <div className="numeric-block">
                    
                        <h3>Benefits Of Online Tutoring Over Prerecorded Videos</h3>
                        <p className="text-muted py-3">Recorded video lessons serve a very good purpose in getting the message across, and it works for less involved learning. Most things on Youtube are great examples, recipe's or how to fix things - to name a few.</p>
                        </div>
                    </div>
                    </div>
                </div>
                </section>

            {/* <div className="container">
                <div className="section three row">
                    <div className="col-sm-12 col-md-5 d-flex flex-column justify-content-center align-items-center">
                        <h3>Parents Looking For A Vetted Online Tutor For Your Child?</h3>
                        <p>The founders of this platform are parents of young children, so we know so well how important the online safety when it comes to our child.</p>

                        <p>Every Tutor who applies to work on our platform is vetted by 2 separate Moderators of the site; we also carry out regular questionairres with students, tutors and parents (if the student is under the age of 18). </p>

                        <p>You can feel very safe having your child learn and advance their academic career when learning with TheTutor.Link.</p>
                    </div>
                    <div className="col-sm-12 col-md-2"></div>
                    <div className="col-sm-12 col-md-5 d-flex align-items-center">
                        <img src={section3} />
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="section four row">
                    <div className="col-md-5">
                        <img src={section4} />
                    </div>
                    <div className="col"></div>
                    <div className="col-md-5 d-flex flex-column justify-content-center align-items-center">
                        <h3>Benefits Of Online Tutoring Over Prerecorded Videos</h3>
                        <p>Recorded video lessons serve a very good purpose in getting the message across, and it works for less involved learning. Most things on Youtube are great examples, recipe's or how to fix things - to name a few.</p>
                    </div>
                </div>
            </div> */}

            <div className="container">
                <div className="section five row">
                    <div className="col-md-6 d-flex flex-column justify-content-center ">
                        <h3>For other subjects, it makes sense to have the one-on-one experience</h3>

                        <div className="row examples">
                            <div className="col-6">
                                <div><span></span><div style={{display: 'inline-block'}}>algebra</div><br/></div>
                                <div><span></span><div style={{display: 'inline-block'}}>accounting</div></div>
                            </div>
                            <div className="col-6">
                                <div><span></span><div style={{display: 'inline-block'}}>languages</div><br/></div>
                                <div><span></span><div style={{display: 'inline-block'}}>accent tuning</div></div>
                            </div>
                        </div>

                        <p>While the above could also be packaged as pre-recorded lessons (theoretically), the student would, essentially, drastically reduce the learning time since the Tutor would assess the students current knowledge and then build on that.</p>

                        <p>So the students spends less time on content which is mostly already known, and focus on what is needed.</p>
                    </div>
                    <div className="col"></div>
                    <div className="col-md-5">
                        <img src={section5} />
                    </div>
                </div>
            </div>
            <div className="grey-background">
                <div className="container">
                    <div className="section six row d-flex justify-content-center">
                         <h3 className="mb-5">Popular Lessons</h3>
                        <div className="col-md-3">
                            <div className="course-cards">
                                <h5 className="mb-4">Maths</h5>
                                <span >18 Trainers</span>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="course-cards">
                                <h5 className="mb-4">English</h5>
                                <span >35 Trainers</span>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="course-cards">
                                <h5 className="mb-4">Advanced English</h5>
                                <span >15 Trainers</span>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="course-cards">
                                <h5 className="mb-4">Polish Spoken</h5>
                                <span >9 Trainers</span>
                            </div>
                        </div>
                        {/* <div className="col-md-3">
                            <div className="course-cards">
                                <h5 className="mb-4">Computer Science</h5>
                                <span >18 Trainers</span>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="course-cards">
                                <h5 className="mb-4">AI Intelligence</h5>
                                <span >35 Trainers</span>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="course-cards">
                                <h5 className="mb-4">English</h5>
                                <span >15 Trainers</span>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="course-cards">
                                <h5 className="mb-4">Hubspot Certification</h5>
                                <span >9 Trainers</span>
                            </div>
                        </div> */}


                        {/* <h3>WITH OVER</h3>

                        <div className="stat">
                            100 <img src={rocket} />
                        </div>

                        <h1>TRAINED PROFESSIONALS</h1>

                        <p>from grade A students to ex army officials; you're<br/>sure to find exacty what you need to learn.</p> */}
                    </div>
                </div>
            </div>
            {/* <div className="container">
                <div className="section seven row">
                    <div className="col-md-8">
                        <img src={StepOneImg} />
                    </div>

                    <div className="col-md-4 d-flex flex-column justify-content-center">
                        <h3>FIND YOUR TUTOR</h3>

                        <p>Tell us what you're looking for and we'll find you Tutors</p>
                    </div>
                </div>
            </div> */}
            

            {/* <div className="container">
                <div className="section eath row">
                    <div className="col-md-4 d-flex flex-column justify-content-center">
                        <h3>BOOK A LESSON</h3>

                        <p>After finding a Tutor, select a time for the lesson and book it</p>
                    </div>

                    <div className="col-md-8 m-order-0" style={{justifyContent: 'flex-end', display: 'flex'}}>
                        <img src={StepTwoImg} style={{margin: 0}} />
                    </div>
                </div>
            </div> */}

            <section className="py-5 testimonials">
                <div className="container pt-5">
                    <div className="row">
                    <div className="col-lg-7 mx-auto text-center">
                        <p className="h6 text-uppercase text-primary">Testimonials</p>
                        <h2>What People Think Of TheTutor.Link?</h2>
                        <p className="text-muted mb-5">Here are a few comments from some of our students, parents of students and tutors.</p>
                    </div>
                    </div>
                    <div className="row align-items-center">


                    <Splide options={ { rewind: true } }>
                            <SplideSlide>
                            <div className="row">
                                <div className="col-lg-6 mb-5 mb-lg-0">
                                    <div className="testimonials-slider owl-carousel owl-theme owl-dots-left">
                                    <div className="item">
                                        <blockquote className="blockquote border-0 p-0">
                                        <p className="blockquote-text text-muted">My 11 year old was having trouble with maths. Unfortunately, I couldn't give him the help he needed. TheTutor.Link helped me find a wonderful maths tutor who knew the curriculum very well and I saw immediate improvements in John. It was so nice to see him looking forward to homework for once!</p>
                                        </blockquote>
                                        <footer className="blockquote-footer">
                                        <div className="media align-items-center">
                                            <img className="rounded-circle shadow-sm" src={testimonials_user} alt="" width="50" />
                                            <div className="media-body ml-3">
                                            <h5 className="text-dark mb-0">Alicia Henderson, Parent.</h5>
                                            <cite className="text-gray">Waterloo</cite>
                                            </div>
                                        </div>
                                        </footer>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-5 mb-lg-0">
                                    <div className="testimonials-slider owl-carousel owl-theme owl-dots-left">
                                    <div className="item">
                                        <blockquote className="blockquote border-0 p-0">
                                        <p className="blockquote-text text-muted">My A-level Economics was coming up and I was nowhere near ready. The tutor I found was really good. The notes she would send me, her pre-lesson prep work was impressive. Every lesson was well paced and she made it very easy for me retain a lot of what was needed to pass.</p>
                                        </blockquote>
                                        <footer className="blockquote-footer">
                                        <div className="media align-items-center">
                                            <img className="rounded-circle shadow-sm" src={testimonials_user1} alt="" width="50" />
                                            <div className="media-body ml-3">
                                            <h5 className="text-dark mb-0">Matilda Jennings, Student.</h5>
                                            <cite className="text-gray">Sunningdale</cite>
                                            </div>
                                        </div>
                                        </footer>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </SplideSlide>
                           
                            </Splide>

                    {/* <div className="col-lg-5 ml-auto">
                        <div className="row">
                        <div className="col-lg-6 col-6 mb-4">
                            <div className="card shadow">
                            <div className="card-body text-center">
                                <i className="fa fa fa-user-o fa-2x"></i>
                                <h3 className="mb-1">1600</h3>
                                <p className="text-muted mb-0">Active users</p>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-6 mb-4">
                            <div className="card shadow">
                            <div className="card-body text-center">
                                <i className="fa fa fa-globe fa-2x"></i>
                                <h3 className="mb-1">2500</h3>
                                <p className="text-muted mb-0">Global clients</p>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-6">
                            <div className="card shadow">
                            <div className="card-body text-center">
                                <i className="fa fa-trophy fa-2x"></i>
                                <h3 className="mb-1">215</h3>
                                <p className="text-muted mb-0">Win award</p>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-6">
                            <div className="card shadow">
                            <div className="card-body text-center">
                                <i className="fa fa-bar-chart fa-2x"></i>
                                <h3 className="mb-1">2150</h3>
                                <p className="text-muted mb-0">Client satisfaction</p>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div> */}
                    </div>
                </div>
                </section>

            <div className="CTA-bacground d-flex flex-column justify-content-center align-items-center p-3">
                <div className="row ">
                    <div className="col-md-12 text-center">
                        <h3 className="white-color text-center">START LEARNING, TUTOR SIGN UP</h3>

                        <p className="white-color text-center">When it’s time for your lesson, enter your Zoom<br/>classroom and connect with your tutor<br/>via live video.</p>
                        {/* <button className="button mt-5 white-button">Student Sign Up</button> */}
                        <button className="button btn-filled red-btn" onClick={() => {
                                    this.props.history.push(`/register`)
                                }}>Tutor Sign Up</button>


                    </div>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(
    mapStateToProps
)(withRouter(Home))


