import React from 'react'

export default class CheckBox extends React.Component {
    state = {
        checked: this.props.checked ? this.props.checked : false
    }

    render() {
        return <div className="check-box" onClick={() => {
            this.setState({checked: !this.state.checked})
            this.props.onClick()
        }}>
            <div className="box">
                {this.state.checked && <span></span>}
            </div>
            <div className="check-box-label">
                {this.props.label}
            </div>
        </div>
    }
}