import React from 'react'
import { getDateWithMonth, getHM } from '../../controllers/DateController'
import MenuBookIcon from '@material-ui/icons/MenuBook';
import paypalLogo from '../../assets/images/paypal.svg'
import lessonApi from '../../apis/lesson'
import { Link, withRouter } from 'react-router-dom';

class BookModal extends React.Component {
    state = {
        subjectId: this.props.bookDetails.subject._id,
        isFetching: false,
        errors: [],
        trial: false
    }

    componentDidMount() {
        if(this.props.bookDetails.subject.trial) {
            this.setState({isFetching: true})
            lessonApi.checkTrial({
                subjectId: this.state.subjectId,
            }).then(response => {
                if(response.success) {
                    if(response.trialStatus) {
                        this.setState({trial: true})
                    }
                }

                this.setState({isFetching: false})
            })
        }
    }

    submit = () => {
        if(this.state.isFetching)
            return

        this.setState({isFetching: true})

        if(!this.state.trial) {
            lessonApi.bookLesson({
                subjectId: this.state.subjectId,
                tutorId: this.props.bookDetails.user._id,
                startTime: this.props.bookDetails.time
            }).then(response => {
                if(response.success) {
                    window.location.href = response.payLink
                } else {
                    this.setState({errors: response.errors})
                }

                this.setState({isFetching: false})
            })
        }

        if(!this.state.trial) {
            lessonApi.bookLesson({
                subjectId: this.state.subjectId,
                tutorId: this.props.bookDetails.user._id,
                startTime: this.props.bookDetails.time
            }).then(response => {
                if(response.success) {
                    window.location.href = response.payLink
                } else {
                    this.setState({errors: response.errors})
                }

                this.setState({isFetching: false})
            })
        }
        
        if(this.state.trial) {
            lessonApi.bookTrialLesson({
                subjectId: this.state.subjectId,
                tutorId: this.props.bookDetails.user._id,
                startTime: this.props.bookDetails.time
            }).then(response => {
                if(response.success) {
                    this.props.history.push('/my-lessons')
                } else {
                    this.setState({errors: response.errors})
                }

                this.setState({isFetching: false})
            })
        }
    }

    render() {
        return <div className="book-modal modal" onClick={() => {
            this.props.onClose()
        }}>
            <div className="modal-dialog" onClick={(e) => {
                e.stopPropagation()
            }}>
                <div className="modal-content">
                    {this.state.isFetching && <div className="d-flex justify-content-center loader">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>}
                    <div className="modal-header">
                        <h5 className="modal-title">Book a lesson</h5>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3 mt-3" className="times">
                            <p>Lesson starts at: <br/><span>{
                                getHM(this.props.bookDetails.time) + 
                                ' | ' + 
                                getDateWithMonth(this.props.bookDetails.time)
                            }</span></p>
                            <p>End of lesson at: <br/><span>{
                                getHM(this.props.bookDetails.time + (Number(this.props.bookDetails.subject.time) * 60 * 1000)) + 
                                ' | ' + 
                                getDateWithMonth(this.props.bookDetails.time + (Number(this.props.bookDetails.subject.time) * 60 * 1000))
                            }</span></p>
                        </div>

                        <p style={{fontSize: 12, color: '#6C6C6C'}}>
                            The lesson will be held in zoom, when the lesson starts you will be sent a link for join to the mail, and it will also be available in the <Link to="/my-lessons"><MenuBookIcon style={{color: 'var(--main-color)'}} size={'small'} /></Link> tab.
                        </p>
                        {!this.state.trial && <p style={{fontSize: 12, color: '#6C6C6C'}}>
                            Payment must be made within 3 hours after booking, then the booking will be remotely automatically, if you do not have time, it is better to cancel the booking and book the lesson again.<br /><br />
                            You can cancel the lesson in the <Link to="/my-lessons"><MenuBookIcon style={{color: 'var(--main-color)'}} size={'small'} /></Link> tab.
                        </p>}

                        {this.state.errors.filter((error) => error.param === 'all').map((error, index) => <span key={index} className="error-msg">{error.msg}</span>)}
                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={() => {
                            this.submit()
                        }} className="button btn-full">Book now for {this.state.trial ? 'free' : <>£{this.props.bookDetails.subject.price}</>}</button>
                        {!this.state.trial && <div className="powered-by-paypal">Powered by <img src={paypalLogo} /></div>}
                    </div>
                </div>
            </div>
        </div>
    }
}


export default withRouter(BookModal)