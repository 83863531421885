import React from "react";
import { withRouter, Link, NavLink } from "react-router-dom"
import { connect } from 'react-redux'
import ForumIcon from '@material-ui/icons/Forum';
import SearchIcon from '@material-ui/icons/Search';
import PersonIcon from '@material-ui/icons/Person';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import logo from '../assets/images/Logo.svg'
import '../assets/styles/partials/header.css'
import { bindActionCreators } from "redux";
import * as userActions from '../store/actions/user'
import Avatar from '../components/profile/Avatar'
import lessons from '../assets/images/lessons.svg'
import chat from '../assets/images/chat.svg'
import facebook from '../assets/images/phone-call.svg'
import ReactTooltip from 'react-tooltip';


const findTutor = {
    tutor: <svg xmlns="http://www.w3.org/2000/svg" width="30" height="32" viewBox="0 0 41 32" fill="none">
    <path d="M0 0V3.2H34.6923V28.8H12.6154V32H41V28.8H37.8462V0H0ZM6.31085 4.8C4.63944 4.8059 3.0381 5.48198 1.85594 6.68084C0.673774 7.87971 0.00664892 9.50414 0 11.2C0 14.7184 2.84477 17.6 6.31085 17.6C7.98141 17.5933 9.58161 16.9167 10.7626 15.7178C11.9436 14.519 12.6096 12.895 12.6154 11.2C12.6154 7.6848 9.77377 4.8 6.31085 4.8ZM15.7692 6.4V9.6H23.6538V6.4H15.7692ZM26.8077 6.4V9.6H31.5385V6.4H26.8077ZM6.31085 8C8.06754 8 9.46154 9.4128 9.46154 11.2C9.46154 12.992 8.06911 14.4 6.31085 14.4C4.54469 14.4 3.15385 12.992 3.15385 11.2C3.15385 9.4128 4.54627 8 6.31085 8ZM15.7692 12.8V16H31.5385V12.8H15.7692ZM0 19.2V32H3.15385V22.4H7.88462V32H11.0385V23.4528L14.2932 25.2C15.2157 25.696 16.3243 25.6944 17.2452 25.2V25.2032L22.8118 22.216L21.3405 19.384L15.7724 22.3712L10.8902 19.7568C10.2089 19.3916 9.44999 19.2004 8.67938 19.2H0Z" fill="#6C6C6C"/>
    </svg>,
    lessons: <svg xmlns="http://www.w3.org/2000/svg" width="34" height="36" viewBox="0 0 34 36" fill="none">
    <path d="M30.9346 31.8188C24.836 31.8188 16.793 35.3542 16.793 35.3542V15.9094C16.793 15.9094 24.7476 12.374 32.7023 12.374L30.9346 31.8188Z" fill="#6C6C6C"/>
    <path d="M2.65149 31.8188C8.75008 31.8188 16.7931 35.3542 16.7931 35.3542V15.9094C16.7931 15.9094 8.83846 12.374 0.883789 12.374L2.65149 31.8188Z" fill="#6C6C6C"/>
    <path d="M16.7935 14.1416C20.6986 14.1416 23.8643 10.9759 23.8643 7.07082C23.8643 3.16571 20.6986 0 16.7935 0C12.8884 0 9.72266 3.16571 9.72266 7.07082C9.72266 10.9759 12.8884 14.1416 16.7935 14.1416Z" fill="#6C6C6C"/>
    <path d="M31.8186 24.7475H32.7024C33.2327 24.7475 33.5863 24.3939 33.5863 23.8636V20.3282C33.5863 19.7979 33.2327 19.4443 32.7024 19.4443H31.8186C30.316 19.4443 29.167 20.5933 29.167 22.0959C29.167 23.5984 30.316 24.7475 31.8186 24.7475Z" fill="#6C6C6C"/>
    <path d="M1.76771 19.4443H0.883853C0.353541 19.4443 0 19.7979 0 20.3282V23.8636C0 24.3939 0.353541 24.7475 0.883853 24.7475H1.76771C3.27025 24.7475 4.41926 23.5984 4.41926 22.0959C4.41926 20.5933 3.27025 19.4443 1.76771 19.4443Z" fill="#6C6C6C"/>
    </svg>,
    chat: <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
    <path d="M26.1 1.46665e-06H2.9C1.30065 1.46665e-06 0 1.2963 0 2.8884V20.3116C0 21.9037 1.30065 23.2 2.9 23.2H7.25V29L16.459 23.2H26.1C27.6994 23.2 29 21.9037 29 20.3116V2.8884C28.9977 2.12105 28.6911 1.38595 28.1474 0.84444C27.6037 0.302925 26.8674 -0.000770117 26.1 1.46665e-06Z" fill="#6C6C6C"/>
    </svg>
  };

class Header extends React.Component {
    state = {
        mbOpen: false
    }

    render() {
        return <div className="mainheader">
   
                <div className="header">    
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-auto logo" onClick={() => {
                        this.props.history.push('/')
                    }}>
                        <img src={logo} alt="TheTutor - learn all what you want." />
                    </div>
                    {/* <div className="col" /> */}
                    <div className="col menu add-menu" style={{textAlign: 'center'}}>
                        <a href="https://thetutor.link/" target="_blank">Blog</a>
                        <a href="tel:07305523333">Call Now</a>
                        <Link to="/contact">Contact</Link>
                        <Link to="/about">About</Link>
                    </div>
                    {/* <div className="col" /> */}
                    <div className="col-auto menu">
                        <NavLink activeClassName="active"  to="/find-tutor" data-tip="Find a Tutor" className="header-link">
                            {/* <SearchIcon /> */}
                            {findTutor.tutor}

                        
                        </NavLink><ReactTooltip />
                        {!this.props.user.isAuth && <>
                            <Link className="button btn-filled" to="/register">SIGNUP</Link>
                            <Link className="button btn-empty" to="/login">Login</Link>
                            
                        </>}
                        {this.props.user.isAuth && <>
                            
                            <NavLink activeClassName="active" to="/my-lessons"  data-tip="My Lessons" className="header-link">
                                {/* <LocalLibraryIcon /> */}
                                {findTutor.lessons}
                            </NavLink><ReactTooltip />
                            <NavLink activeClassName="active" to="/chats"  data-tip="My Chats" className="header-link" style={{position: 'relative'}}>
                                {/* <ForumIcon /> */}
                                {findTutor.chat}
                                {this.props.user.noRead > 0 &&<span style={{right: 0, top: 11}} className="position-absolute translate-middle badge rounded-pill bg-danger">
                                    {this.props.user.noRead}
                                    <span className="visually-hidden">unread chats</span>
                                </span>}
                            </NavLink><ReactTooltip />
                        </>}
                        {this.props.user.isAuth && <Link to={`/profiles/${this.props.user._id}`}>
                            {/* {this.props.user.type === 'student' && <Link className="button btn-filled" to="/find-tutor"><SearchIcon /> Find a tutor</Link>} */}
                            {/* <Link to="/chats" className="button btn-filled btn-circle">
                                <ForumIcon />
                                {this.props.user.noRead > 0 &&<span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                    {this.props.user.noRead}
                                    <span className="visually-hidden">unread chats</span>
                                </span>}
                            </Link>
                            <button className="button btn-filled btn-circle my-profile">
                                <PersonIcon />
                                <div className="profile-options">
                                    <div className="card">
                                        <Link to={`/profiles/${this.props.user._id}`}><PersonIcon /> My profile</Link>
                                        <Link to="#" onClick={(e) => {
                                            e.preventDefault()

                                            this.props.userActions.logoutUser()
                                        }}><ExitToAppIcon /> Log out</Link>
                                    </div>
                                </div>
                            </button> */}
                            <span className="user-name">{this.props.user.fullName}</span>
                            <Avatar size={42} user={this.props.user} />
                        </Link>}
                    </div>
                    <div className="col-auto">
                        <div className="menu-toggle" onClick={() => {
                            this.setState({mbOpen: true})
                        }}>
                            <span className="sq-1"></span>
                            <span className="sq-2"></span>
                            <span className="sq-3"></span>
                            <span className="sq-4"></span>
                        </div>
                    </div>
                    
                </div>
            </div>

            {this.state.mbOpen && <div className="mobile-menu">
                <div className="menu-close-icon"  onClick={() => {
                            this.setState({mbOpen: false})
                        }}>
                <i class="fa fa-times fa-2x" aria-hidden="true"></i>
                </div>



        

                
                <NavLink onClick={() => {this.setState({mbOpen: false})}} className="mobile-menu-btn" to="/home">Home</NavLink>
               
                <NavLink onClick={() => {this.setState({mbOpen: false})}} className="mobile-menu-btn" to="/about">About</NavLink>
                
                <NavLink onClick={() => {this.setState({mbOpen: false})}} className="mobile-menu-btn" to="/find-tutor">Find a tutor</NavLink>
                
                {this.props.user.isAuth && <>
                    <NavLink onClick={() => {this.setState({mbOpen: false})}} to="/chats" className="mobile-menu-btn">
                        Chats
                        {this.props.user.noRead > 0 &&<span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                            {this.props.user.noRead}
                            <span className="visually-hidden">unread chats</span>
                        </span>}
                    </NavLink>
                    <NavLink onClick={() => {this.setState({mbOpen: false})}} to="/my-lessons" className="mobile-menu-btn">
                        My lessons
                    </NavLink>
                    <NavLink onClick={() => {this.setState({mbOpen: false})}} to={`/profiles/${this.props.user._id}`} className="mobile-menu-btn">My profile</NavLink>
                </>}

                {!this.props.user.isAuth && <>
                    <NavLink onClick={() => {this.setState({mbOpen: false})}} className="mobile-menu-btn" to="/login">Login</NavLink>
                    {/* <span className="auth-option-text">or</span> */}
                    <NavLink onClick={() => {this.setState({mbOpen: false})}} className="mobile-menu-btn" to="/register">Register</NavLink>
                </>}

                


                <a href="https://thetutor.link/" target="_blank" className="mobile-menu-btn">Blog</a>
                <a href="tel:07305523333" className="mobile-menu-btn">0730 552 3333</a>
                <NavLink onClick={() => {this.setState({mbOpen: false})}} className="mobile-menu-btn" to="/contact">Contact</NavLink>
            </div>}
                </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Header))
