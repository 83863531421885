import React from 'react'
import '../../../node_modules/font-awesome/css/font-awesome.min.css'; 
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import section4 from '../../assets/images/section4.png'
import about2 from '../../assets/images/about-2.png'
import about3 from '../../assets/images/about-3.png'
import about4 from '../../assets/images/about-4.png'
import img_2 from '../../assets/images/demo-img-3.jpg'
import img_3 from '../../assets/images/demo-img-4.jpg'
import img_4 from '../../assets/images/demo-img-5.jpg'

class About extends React.Component {
    render() {
        return <div className="about">
            <section className="hero with-pattern py-5 bg-light">
                <div className="container">
                    <div className="row mb-4">
                    <div className="col-md-7">
                        <p className="h6 text-uppercase text-primary">About us</p>
                        <h1>We share knowledge with the world</h1>
                        <p className="text-muted"> TheTutor.Link is an online platform that connects young tutors to students for sessions of 1-1 learning to help with any academic areas that they are struggling with, or simply; have further interest in.</p>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-6">
                        <h4>Changing learning for the better</h4>
                        <p className="text-muted">Our dynamic online tutoring platform allows students to get personalised assistance from our tutors who have recent experience in the schooling system. 
</p>
                    </div>
                    <div className="col-md-6">
                        <p className="text-muted">This helps build better relationships between the student and tutor as there is relatability, plus, gives our tutors a chance to empower other people with their knowledge and earn remotely when looking for a post-grad job or to earn some extra income during their later studies.</p>
                        {/* <p className="text-muted text-small">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
                    </div>
                    </div>
                </div>
            </section>
            {/* <section className="pulled-up-section mb-5">
                <div className="container">
                    <div className="card shadow-lg bg-primary text-white with-pattern-full">
                    <div className="card-body p-lg-5">
                        <div className="row py-4">
                        <div className="col-lg-4 mb-4 mb-lg-0">
                            <div className="media">
                            <div className="icon-block bg-white">
                                
                                <i className="fa fa-cubes fa-2x"></i>
                               
                            </div>
                            <div className="media-body ">
                                <h5 className="mb-2 ml-2">Simplicity And Choice</h5>
                                <p className="text-small">Lorem ipsum dolor sit amet, conse ctetur dolor adipisicing elit.</p>
                                <ul className="list-check list-check-white mb-0 text-small font-weight-normal">
                                <li className="mb-1">Cost Accounting Fundamentals</li>
                                <li className="mb-1">Corporate Cash Management</li>
                                <li className="mb-1">SEO Optimization Services</li>
                                <li className="mb-1">Company Brand Solutions</li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-4 mb-lg-0">
                            <div className="media">
                            <div className="icon-block bg-white">
                                <i class="fa fa-certificate fa-2x"></i>
                            </div>
                            <div className="media-body ml-3">
                                <h5 className="mb-2">Worry-Free Experience</h5>
                                <p className="text-small">Lorem ipsum dolor sit amet, conse ctetur dolor adipisicing elit.</p>
                                <ul className="list-check list-check-white mb-0 text-small font-weight-normal">
                                <li className="mb-1">Cost Accounting Fundamentals</li>
                                <li className="mb-1">Corporate Cash Management</li>
                                <li className="mb-1">SEO Optimization Services</li>
                                <li className="mb-1">Company Brand Solutions</li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="media">
                            <div className="icon-block bg-white">
                                <i className="fa fa-cubes fa-2x"></i>
                            </div>
                            <div className="media-body ml-3">
                                <h5 className="mb-2">Performance That Scale</h5>
                                <p className="text-small">Lorem ipsum dolor sit amet, conse ctetur dolor adipisicing elit.</p>
                                <ul className="list-check list-check-white mb-0 text-small font-weight-normal">
                                <li className="mb-1">Cost Accounting Fundamentals</li>
                                <li className="mb-1">Corporate Cash Management</li>
                                <li className="mb-1">SEO Optimization Services</li>
                                <li className="mb-1">Company Brand Solutions</li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section> */}

            <section className="py-5">
                <div className="container py-5">
                    <div className="row align-items-center pb-5">
                    <div className="col-lg-6 order-2 order-lg-1">
                        <div className="numeric-block">
                     
                        <p className="text-muted text-uppercase mb-2">Working closely togeather</p>
                        <h2>Our Vision</h2>
                        <p className="text-muted py-3">Whether you're looking to get some extra help with your kids' studies and can’t find a suitable tutor within your budget or you are a young student who feels they have experience and knowledge to share, our platform aims to unite like-minded students and tutors who want self-improvement with learning or a chance to earn some extra cash.</p>
                        <ul className="list-check row">
                            <li className="col-lg-6">
                            <p className="mb-2 font-weight-mormal">Quality Education</p>
                            </li>
                            <li className="col-lg-6">
                            <p className="mb-2 font-weight-mormal">Freelance Tutors</p>
                            </li>
                            <li className="col-lg-6">
                            <p className="mb-2 font-weight-mormal">Safety</p>
                            </li>
                            <li className="col-lg-6">
                            <p className="mb-2 font-weight-mormal">Brand Solutions</p>
                            </li>
                        </ul>
                        </div>
                    </div>
                    <div className="col-lg-5 ml-auto order-1 order-lg-2 mb-5 mb-lg-0">
                        <div className="img-gradient img-fluid-about img-gradient-right video-block">
                            <img className="img-fluid shadow rounded" src={img_2} />  
                        </div>
                    </div>
                    </div>
                    <div className="row align-items-center pt-lg-5">
                    <div className="col-lg-5 mb-5 mb-lg-0">
                        <div className="img-gradient img-fluid-about2 img-gradient-right video-block">
                            <img className="img-fluid shadow rounded" src={img_3} />
                        </div>
                    </div>
                    <div className="col-lg-6 ml-auto">
                        <div className="numeric-block">
                 
                        <p className="text-muted text-uppercase mb-2">Working closely togeather</p>
                        <h2>Why Choose The Tutor Link?</h2>
                        <p className="text-muted py-3">The tutor link differs from other online tutoring systems as it focuses on connecting young tutors with students, this smaller age gap can help with learning as young tutors are experienced with the learning system themselves. With the tutoring link, we provide ongoing support for your child with its learning, with questionnaires for feedback and all of our tutors are fully vetted.</p>
                        <ul className="list-check row">
                            <li className="col-lg-6">
                            <p className="mb-2 font-weight-mormal">Ongoing Support</p>
                            </li>
                            <li className="col-lg-6">
                            <p className="mb-2 font-weight-mormal">Learning Opportunities</p>
                            </li>
                            <li className="col-lg-6">
                            <p className="mb-2 font-weight-mormal">Young Experienced Tutors</p>
                            </li>
                            <li className="col-lg-6">
                            <p className="mb-2 font-weight-mormal">Online Tutoring Systems</p>
                            </li>
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
            </section>

            <section class="py-5 bg-primary text-white with-pattern-full">
                <div class="container py-5">
                    <div class="row">
                    <div class="col-lg-4 mb-4 mb-lg-0">
                        <div class="media align-items-center">
                        <div class="icon-block icon-block-lg bg-white shadow">
                            
                                <i className="fa fa-university fa-2x"></i>
                            
                        </div>
                        <div class="media-body ml-3">
                            <h5>Subjects</h5>
                            <p class="text-small mb-0">We provide access to a wide range of subjects</p>
                        </div>
                        </div>
                    </div>
                    <div class="col-lg-4 mb-4 mb-lg-0">
                        <div class="media align-items-center">
                        <div class="icon-block icon-block-lg bg-white shadow">
                            <i className="fa fa-leanpub fa-2x"></i>
                        </div>
                        <div class="media-body ml-3">
                            <h5>Learning System</h5>
                            <p class="text-small mb-0">Our tutors are experienced in the learning system due to their young age.</p>
                        </div>
                        </div>
                    </div>
                    <div class="col-lg-4 mb-4 mb-lg-0">
                        <div class="media align-items-center">
                        <div class="icon-block icon-block-lg bg-white shadow">
                            <i className="fa fa-money fa-2x"></i>
                        </div>
                        <div class="media-body ml-3">
                            <h5>Budget</h5>
                            <p class="text-small mb-0">Lessons are chosen according to budget.</p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>

            <section class="py-5">
                <div class="container">
                    <div class="row align-items-center">
                    <div class="col-md-7 order-2 order-md-1">
                        <p class="text-muted text-uppercase mb-2">Our Best Services</p>
                        <h2>How We Work </h2>
                        <p class="text-muted py-3">The process of finding a tutor on TheTutor.Link; or even becoming a tutor yourself is simple. </p>

                        <p class="text-muted py-3">If you want to be a tutor, simply <a href="/register" className="link-text">sign up here</a>, fill out your profile and add any relevant certificates. 

                        <p class="text-muted py-3">Once we have vetted your profile and certificates, you will receive an email asking you to create “lessons”. It is these “lessons” which the students will book and pay (in advance).

Both parties will get confirmation of the lesson and will be available to chat directly with one another. </p>

<p class="text-muted">The lesson itself will take place on Zoom.

On completion, both parties will have the opportunity to review one another.</p>

</p>
                        {/* <div class="row">
                        <div class="col-md-6 mb-4">
                            <div class="media">
                            <div class="icon-block">
                                <i className="fa fa-cubes fa-2x"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5>Strategic vision</h5>
                                <p class="text-small mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-4">
                            <div class="media">
                            <div class="icon-block">
                                <i className="fa fa-bullseye fa-2x"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5>Business growth</h5>
                                <p class="text-small mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-4 mb-md-0">
                            <div class="media">
                            <div class="icon-block">
                                <i className="fa fa-line-chart fa-2x"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5>Leadership work</h5>
                                <p class="text-small mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="media">
                            <div class="icon-block">
                                <i className="fa fa-certificate fa-2x"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5>Success fulfill</h5>
                                <p class="text-small mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                            </div>
                            </div>
                        </div>
                        </div> */}
                    </div>
                    <div class="col-md-5 order-1 order-md-2 mb-5 mb-md-0">
                        <div class="img-gradient img-gradient-right video-block img-fluid-about"><img class="img-fluid shadow rounded" src={img_4} alt="" /></div>
                    </div>
                    </div>
                </div>
            </section>

            <section class="py-5 bg-primary text-white has-quote">
                <div class="container py-5 index-forward">
                    <div class="row text-center">
                    <div class="col-lg-7 mx-auto">
                        <h2 class="mb-4">More Burning Questions?</h2>
                        
                        <p class="h6 mb-0">If you still have any questions, please feel free to send us an email <span> <a href="mailto:support@thetutor.link" className="white-text" > support@thetutor.link </a></span>or call us on <span> <a href="tel:07305523333" className="white-text" > 0730 552 3333 </a> </span></p>
                    </div>
                    </div>
                </div>
            </section>
                        

        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(
    mapStateToProps
)(withRouter(About))
