import React from 'react'
import SimpleBar from 'simplebar-react';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

export default class SimpleSelect extends React.Component {
    constructor(props) {
        super(props);

        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    state = {
        show: false,
        q: ''
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({show: false})
        }
    }

    render() {
        return <div ref={this.wrapperRef} tabIndex="0" className={`input-simple select ${this.state.show ? 'active' : ''}`} onClick={() => {
            this.setState({show: true})
        }}>

            {this.state.show && <input type="text" style={{border: 0}} autoFocus={true} value={this.state.q} onChange={(e) => {
                this.setState({q: e.target.value})
            }} />}
            {!this.state.show && <>{!!this.props.values.find(value => value.value === this.props.value) ? this.props.values.find(value => value.value === this.props.value).label : 'Select value'}</>}

            {!this.state.show && <ArrowRightIcon />}
            {this.state.show && <ArrowDropDownIcon />}

            <SimpleBar className="values">
                {!this.state.q.length && this.props.values.map(value => {
                    return <div key={value.value} className={`value ${value.value === this.props.value ? 'active' : ''}`} onClick={(e) => {
                        e.stopPropagation()
                        this.setState({show: false, q: ''})
                        this.props.onClick(value.value)
                    }}>
                        {value.label}
                    </div>
                })}

                {!!this.state.q.length && this.props.values.filter(value => value.label.toLowerCase().includes(this.state.q.toLowerCase())).map(value => {
                    return <div key={value.value} className={`value ${value.value === this.props.value ? 'active' : ''}`} onClick={(e) => {
                        e.stopPropagation()
                        this.setState({show: false, q: ''})
                        this.props.onClick(value.value)
                    }}>
                        {value.label}
                    </div>
                })}

                {!!this.state.q.length && !this.props.values.filter(value => value.label.toLowerCase().includes(this.state.q.toLowerCase())).length && <div style={{textAlign: 'center'}}>Nothing found</div>}
            </SimpleBar>
        </div>
    }
}