import React from 'react'
import countries from "countries-list";
// import Rating from './Rating';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Avatar from './Avatar';
import CountryFlag from '../ContryFalg';
import lessonApi from '../../apis/lesson'

class SubjectCard extends React.Component {
    state = {
        trial: false
    }

    componentDidMount() {
        lessonApi.checkTrial({
            subjectId: this.props.subject._id,
        }).then(response => {
            if(response.success) {
                if(response.trialStatus) {
                    this.setState({trial: true})
                }
            }

            this.setState({isFetching: false})
        })
    }

    render() {
        return <div className="tutor-card col-12 col-md-12 col-lg-12">
            <div className="avatar">
                <Avatar avatar={this.props.subject.thumbnail} size={160} />
            </div>
            <div className="info col">
                <div className="name" style={{color: '#000000'}}>
                    {this.props.subject.title}
                </div>
                {!!this.props.subject.keys.length && <div className="description">
                    {this.props.subject.keys.map(key => {
                        return <div>{key}</div>
                    })}
                </div>}
            </div>
            <div className="actions" style={{minWidth: 140}}>
                {this.props.user.isAuth && this.props.userId !== this.props.user._id && <>
                    <button onClick={() => {this.props.onBook({...this.props.subject, trial: false})}} className="button btn-empty">Book a lesson</button>
                    {this.state.trial &&  <button onClick={() => {this.props.onBook(this.props.subject)}} className="button btn-empty">Book a free trial</button>}
                </>}
                {this.props.user.isAuth && this.props.userId === this.props.user._id && <>
                    <Link to={`/edit-lesson/${this.props.subject._id}`} className="button btn-empty">Edit a lesson</Link>
                </>}
                <div className="price">
                    <span>£{this.props.subject.price}</span>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
  }
  
export default connect(
    mapStateToProps
)(withRouter(SubjectCard))