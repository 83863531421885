import React from 'react'

class Pagination extends React.Component {
    render() {
        if(!this.props.pagination.pages) {
            return <></>
        }

        return <div className="pagination-box col-12 col-md-12 col-lg-10">
            <div className="row">
                <ul className="pagination b-pagination pagination-md justify-content-center">
                    <li onClick={() => this.props.paginate(1)} role="none presentation" className={`page-item ${this.props.currentPage === 1 ? 'disabled' : ''}`}>
                        <span role="menuitem" className="page-link">«</span>
                    </li>
                    <li onClick={() => this.props.paginate(this.props.currentPage - 1)} role="none presentation" className={`page-item ${this.props.currentPage === 1 ? 'disabled' : ''}`}>
                        <span role="menuitem" className="page-link">‹</span>
                    </li>

                    {this.props.currentPage-2 > 1 && <li className="page-item disabled bv-d-xs-down-none">
                        <span className="page-link">…</span>
                    </li>}
                    
                    {Array.from(Array(this.props.pagination.pages).keys()).filter(x => !((x+3 < this.props.currentPage || x-1 > this.props.currentPage) && x !== this.props.currentPage) ).map(pg => {
                        return <li 
                            key={pg}
                            role="none presentation" 
                            className={`page-item ${this.props.currentPage === pg+1 ? 'active' : ''}`}
                        >
                            <span onClick={() => this.props.paginate(pg+1)} className="page-link">{pg+1}</span>
                        </li>
                    })}

                    {this.props.currentPage+2 < this.props.pagination.pages && <li className="page-item disabled bv-d-xs-down-none">
                        <span className="page-link">…</span>
                    </li>}

                    <li onClick={() => this.props.paginate(this.props.currentPage+1)} className={`page-item ${this.props.currentPage === this.props.pagination.pages ? 'disabled' : ''}`}>
                        <span role="menuitem" className="page-link">›</span>
                    </li>
                    <li onClick={() => this.props.paginate(this.props.pagination.pages)}  className={`page-item ${this.props.currentPage === this.props.pagination.pages ? 'disabled' : ''}`}>
                        <span role="menuitem" className="page-link">»</span>
                    </li>
                </ul>
            </div>
        </div>
    }
}

export default Pagination