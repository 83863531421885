import React from 'react'
import tutorApi from '../../apis/tutor'
import Pagination from '../Pagination'
import SearchIcon from '@material-ui/icons/Search';
import SubjectCard from './SubjectCard';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class Reviews extends React.Component {
    state = {
        subjects: [],
        pagination: {},
        isFetching: true,
    }

    componentDidMount() {
        this.getSubjects({page: 1})
    }

    getSubjects = (params) => {
        this.setState({isFetching: true})
        
        tutorApi.getSubjects({...params, perPage: 3, userId: this.props.userId}).then(response => {
            if(response.success) {
                this.setState({subjects: response.subjects, pagination: response.pagination})
            }

            this.setState({isFetching: false})
        })
    }

    render() {
        return <div style={{position: 'relative'}}>
            <div className="title" style={{display: 'flex', alignItems: 'center'}}>
                Lessons
                <div className="col"></div>
                {this.props.user._id === this.props.userId && <Link className="button btn-empty" to="/create-lesson">Create a lesson</Link>}
            </div>

            {this.state.isFetching && <div className="d-flex justify-content-center loader">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>}

            {!this.state.isFetching && !!this.state.subjects.length && this.state.subjects.map(subject => {
                return <SubjectCard subject={subject} key={subject._id} userId={this.props.userId} onBook={subject => {this.props.onBook(subject)}} />
            })}

            <Pagination paginate={(page) => {
                this.getSubjects({page})
            }} pagination={this.state.pagination} currentPage={this.state.pagination.page} />

            {!this.state.isFetching && !this.state.subjects.length && <div style={{minHeight: 200, width: '100%'}}>
                <div className="empty-data">
                    <SearchIcon /><br/>
                    No lessons yet.
                </div>
            </div>}
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
  }
  
export default connect(
    mapStateToProps
)(Reviews)